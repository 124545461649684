import type { TooltipProps } from "recharts";

import type { DataPoint } from ".";
import { cn } from "../../../lib/utils";
import type { CustomLabelProps } from "./CustomLabel";
import { ScoreBadge } from "./CustomLabel";

export const renderCustomTooltip =
  (data: DataPoint[], multi?: boolean) =>
  ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload?.length) {
      const { description, values, valueIndex } =
        data.find((d) => d.label === label) ?? {};
      const total = multi
        ? values?.reduce((acc, v) => acc + v.value, 0) ?? 0
        : values?.[valueIndex ?? 0]?.value ?? 0;

      if (!values?.length) return null;

      return (
        <CustomTooltip
          value={total}
          label={label as string}
          pillar={values[0]!.pillar}
          description={description}
        />
      );
    }

    return null;
  };

interface CustomTooltipProps extends CustomLabelProps {
  className?: string;
}

export const CustomTooltip = (props: CustomTooltipProps) => {
  const { value, pillar, label, description } = props;

  return (
    <div
      className={cn(
        "flex w-72 animate-fade flex-col items-stretch gap-4 overflow-auto rounded-2xl border bg-base-100 px-5 py-4 shadow-xl duration-200",
        props.className,
      )}
    >
      <div className="flex justify-between gap-4">
        <div className="font-semibold text-base-placeholder">{label}</div>
        <ScoreBadge value={value} pillar={pillar} />
      </div>
      <div>{description}</div>
    </div>
  );
};
