import type { ImageProps } from "next/image";
import Image from "next/image";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

import { cn } from "~/lib/utils";

export const imageVariants = cva("", {
  variants: {
    variant: {
      default: "",
      "fade-in": `transition-opacity duration-300 ease-in-out`,
    },
    hasLoaded: {
      true: "opacity-100",
      false: "opacity-0",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface NextImageProps
  extends ImageProps,
    VariantProps<typeof imageVariants> {}

export const NextImage = ({
  variant,
  className,
  src,
  ...props
}: NextImageProps) => {
  return (
    <Image
      draggable={false}
      {...props}
      src={src}
      alt={props.alt}
      className={cn(
        imageVariants({
          variant,
          className: twMerge(className),
        }),
      )}
    />
  );
};
