import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { create } from "zustand";

import { useProtocolStore } from ".";

export type SideDrawerMode =
  | "closed"
  | "my-protocols"
  | "protocol-customization";

interface SideDrawerStoreState {
  mode: SideDrawerMode;
  previousMode: SideDrawerMode | null;
  /**
   * Sets the mode of the side drawer. If the mode was previously set to "protocol-customization" and there are unsaved changes,
   * the user will be prompted to discard their changes (setting showDiscardChangesDialog to true).
   * @param mode The mode to set the side drawer to
   * @param opts Options for setting mode: force - if true, the mode will be set without checking for unsaved changes
   */
  setMode: (mode: SideDrawerMode, opts?: { force: boolean }) => void;
  isOpening: boolean;
  showDiscardChangesDialog: boolean;
}

/**
 * Store for managing the state of the side drawer. The side drawer can be in one of the following modes:
 * - closed: the side drawer is closed
 * - my-protocols: the side drawer is open and showing the user's implemented protocols
 * - protocol-customization: the side drawer is open and showing the details of a protocol
 */
export const useSideDrawerStore = create<SideDrawerStoreState>((set) => ({
  mode: "closed",
  previousMode: null,
  isOpening: false,
  showDiscardChangesDialog: false,
  setMode: (mode, opts) => {
    set((storeState) => {
      const wasClosed = storeState.mode === "closed";
      const hasUnsavedChanges = useProtocolStore
        .getState()
        .getHasUnsavedChanges();

      if (
        !opts?.force &&
        storeState.mode === "protocol-customization" &&
        hasUnsavedChanges
      ) {
        return {
          ...storeState,
          showDiscardChangesDialog: true,
        };
      }

      if (
        storeState.mode === "protocol-customization" &&
        mode !== "protocol-customization"
      ) {
        useProtocolStore.setState({
          focusedBaseProtocolId: null,
          focusedProtocolId: null,
        });
      }

      if (wasClosed) {
        setTimeout(() => {
          // used for better UX where side drawer components don't expand until the drawer is fully open
          set((storeState) => ({ ...storeState, isOpening: false }));
        }, 300);
      }

      return {
        ...storeState,
        previousMode: storeState.mode,
        isOpening: wasClosed,
        mode: mode,
      };
    });
  },
}));

interface QueryParams {
  [key: string]: string | undefined; // Define a key-value pair, making values optional
  panel?: string; // Explicitly mark drawerMode as optional
}

export const useSyncUrlWithSideDrawerStore = () => {
  const { protocols, focusedProtocolId } = useProtocolStore();
  const [hasSynced, setHasSynced] = useState(false);
  const router = useRouter();
  const query = router.query;

  // set the mode of the side drawer based on the URL query parameter
  useEffect(() => {
    const mode = query.panel as SideDrawerMode | undefined;
    const queryProtocolId = query.protocolId as string | undefined;

    if (mode) {
      useSideDrawerStore.setState({ mode });
    }
    if (queryProtocolId) {
      useProtocolStore.setState({ focusedProtocolId: queryProtocolId });
    }
    setHasSynced(true);
  }, [query]);

  useEffect(() => {
    if (!focusedProtocolId) return;
    const baseProtocolId = protocols[focusedProtocolId]?.baseProtocolId;
    useProtocolStore.setState({ focusedBaseProtocolId: baseProtocolId });
  }, [protocols, focusedProtocolId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const unsubscribe = useSideDrawerStore.subscribe(async (state) => {
      const { mode } = state;
      const newQuery: QueryParams = {
        ...query,
        panel: mode,
      };

      if (mode === "protocol-customization") {
        // this is a workaround to ensure that the protocolId is set after the protocol store has been updated
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 100));
        newQuery.protocolId =
          useProtocolStore.getState().focusedProtocolId ?? undefined;
      } else {
        delete newQuery.protocolId;
      }

      if (mode === "closed") {
        delete newQuery.panel;
      }
      void router.replace(
        {
          pathname: query.pathname as string,
          query: newQuery,
        },
        undefined,
        { shallow: true },
      );
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return { hasSynced };
};
