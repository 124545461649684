import type { Config } from "tailwindcss";

import { scrollBarUtils } from "./utils";

export default {
  darkMode: "class",
  content: ["src/**/*.{ts,tsx}"],
  theme: {
    extend: {
      ringColor: {
        DEFAULT: "hsl(var(--ring))",
      },
      opacity: {
        6: "0.06",
        12: "0.12",
        18: "0.18",
      },
      height: {
        "dynamic-screen": "100dvh",
      },
      width: {
        "screen-sidebar": "calc(100vw - 96px)",
      },
      maxWidth: {
        "screen-sidebar": "calc(100vw - 96px)",
      },
      colors: {
        border: "hsl(var(--border), <alpha-value>)",
        input: "hsl(var(--input), <alpha-value>)",
        ring: "hsl(var(--ring), <alpha-value>)",
        background: "hsl(var(--background), <alpha-value>)",
        foreground: "hsl(var(--foreground), <alpha-value>)",

        "base-100": "hsl(var(--base-100), <alpha-value>)",
        "base-200": "hsl(var(--base-200), <alpha-value>)",
        "base-300": "hsl(var(--base-300), <alpha-value>)",
        "base-placeholder": "hsl(var(--base-placeholder), <alpha-value>)",
        "base-content": "hsl(var(--base-content), <alpha-value>)",

        grapefruit: {
          50: "hsl(var(--grapefruit-50), <alpha-value>)",
          100: "hsl(var(--grapefruit-100), <alpha-value>)",
          200: "hsl(var(--grapefruit-200), <alpha-value>)",
          300: "hsl(var(--grapefruit-300), <alpha-value>)",
          400: "hsl(var(--grapefruit-400), <alpha-value>)",
          500: "hsl(var(--grapefruit-500), <alpha-value>)",
          600: "hsl(var(--grapefruit-600), <alpha-value>)",
          700: "hsl(var(--grapefruit-700), <alpha-value>)",
          800: "hsl(var(--grapefruit-800), <alpha-value>)",
          900: "hsl(var(--grapefruit-900), <alpha-value>)",
        },

        primary: {
          DEFAULT: "hsl(var(--primary), <alpha-value>)",
          foreground: "hsl(var(--primary-foreground), <alpha-value>)",
          subtle: "hsl(var(--primary), var(--opacity-subtle))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary), <alpha-value>)",
          foreground: "hsl(var(--secondary-foreground), <alpha-value>)",
        },
        muted: {
          DEFAULT: "hsl(var(--muted), <alpha-value>)",
          foreground: "hsl(var(--muted-foreground), <alpha-value>)",
        },
        accent: {
          DEFAULT: "hsl(var(--accent), <alpha-value>)",
          foreground: "hsl(var(--accent-foreground), <alpha-value>)",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive), <alpha-value>)",
          foreground: "hsl(var(--destructive-foreground), <alpha-value>)",
        },
        recovery: {
          DEFAULT: "hsl(var(--recovery), <alpha-value>)",
          bg: "hsl(var(--recovery-bg), <alpha-value>)",
          subtle: "hsl(var(--recovery), var(--opacity-subtle))",
          foreground: "hsl(var(--recovery-foreground), <alpha-value>)",
        },
        mindfulness: {
          DEFAULT: "hsl(var(--mindfulness), <alpha-value>)",
          bg: "hsl(var(--mindfulness-bg), <alpha-value>)",
          subtle: "hsl(var(--mindfulness), var(--opacity-subtle))",
          foreground: "hsl(var(--mindfulness-foreground), <alpha-value>)",
        },
        nutrition: {
          DEFAULT: "hsl(var(--nutrition), <alpha-value>)",
          bg: "hsl(var(--nutrition-bg), <alpha-value>)",
          subtle: "hsl(var(--nutrition), var(--opacity-subtle))",
          foreground: "hsl(var(--nutrition-foreground), <alpha-value>)",
        },
        fitness: {
          DEFAULT: "hsl(var(--fitness), <alpha-value>)",
          bg: "hsl(var(--fitness-bg), <alpha-value>)",
          subtle: "hsl(var(--fitness), var(--opacity-subtle))",
          foreground: "hsl(var(--fitness-foreground), <alpha-value>)",
        },

        popover: {
          DEFAULT: "hsl(var(--popover), <alpha-value>)",
          foreground: "hsl(var(--popover-foreground), <alpha-value>)",
        },
        card: {
          DEFAULT: "hsl(var(--card), <alpha-value>)",
          foreground: "hsl(var(--card-foreground), <alpha-value>)",
        },
      },
      borderRadius: {
        // lg: "var(--radius)",
        // md: "calc(var(--radius) - 2px)",
        // sm: "calc(var(--radius) - 4px)",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0px" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0px" },
        },
        fadeIn: {
          "0%": { opacity: "0" },
          "100%": { opacity: "1" },
        },
      },
      animation: {
        fade: "fadeIn ease-in-out",
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
    },
  },
  plugins: [
    require("@tailwindcss/forms"),
    require("@tailwindcss/typography"),
    require("tailwindcss-animate"),
    scrollBarUtils,
  ],
} satisfies Config;

export const colors = {
  "base-100": `hsl(var(--base-100))`,
  "base-200": `hsl(var(--base-200))`,
  "base-300": `hsl(var(--base-300))`,
  "base-placeholder": `hsl(var(--base-placeholder))`,
  "base-content": `hsl(var(--base-content))`,

  "grapefruit-50": `hsl(var(--grapefruit-50))`,
  "grapefruit-100": `hsl(var(--grapefruit-100))`,
  "grapefruit-200": `hsl(var(--grapefruit-200))`,
  "grapefruit-300": `hsl(var(--grapefruit-300))`,
  "grapefruit-400": `hsl(var(--grapefruit-400))`,
  "grapefruit-500": `hsl(var(--grapefruit-500))`,
  "grapefruit-600": `hsl(var(--grapefruit-600))`,
  "grapefruit-700": `hsl(var(--grapefruit-700))`,
  "grapefruit-800": `hsl(var(--grapefruit-800))`,
  "grapefruit-900": `hsl(var(--grapefruit-900))`,

  // continue...
  primary: `hsl(var(--primary))`,
  "primary-foreground": `hsl(var(--primary-foreground))`,
  "primary-subtle": `hsl(var(--primary), var(--opacity-subtle))`,

  secondary: `hsl(var(--secondary))`,
  "secondary-foreground": `hsl(var(--secondary-foreground))`,

  muted: `hsl(var(--muted))`,
  "muted-foreground": `hsl(var(--muted-foreground))`,

  accent: `hsl(var(--accent))`,
  "accent-foreground": `hsl(var(--accent-foreground))`,

  destructive: `hsl(var(--destructive))`,
  "destructive-foreground": `hsl(var(--destructive-foreground))`,

  recovery: `hsl(var(--recovery))`,
  "recovery-bg": `hsl(var(--recovery-bg))`,
  "recovery-subtle": `hsl(var(--recovery), var(--opacity-subtle))`,
  "recovery-foreground": `hsl(var(--recovery-foreground))`,

  mindfulness: `hsl(var(--mindfulness))`,
  "mindfulness-bg": `hsl(var(--mindfulness-bg))`,
  "mindfulness-subtle": `hsl(var(--mindfulness), var(--opacity-subtle))`,
  "mindfulness-foreground": `hsl(var(--mindfulness-foreground))`,

  nutrition: `hsl(var(--nutrition))`,
  "nutrition-bg": `hsl(var(--nutrition-bg))`,
  "nutrition-subtle": `hsl(var(--nutrition), var(--opacity-subtle))`,
  "nutrition-foreground": `hsl(var(--nutrition-foreground))`,

  fitness: `hsl(var(--fitness))`,
  "fitness-bg": `hsl(var(--fitness-bg))`,
  "fitness-subtle": `hsl(var(--fitness), var(--opacity-subtle))`,
  "fitness-foreground": `hsl(var(--fitness-foreground))`,
};
