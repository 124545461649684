// Importing dayjs and AdvancedFormat plugin
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import type { DayOfWeekType } from "@acme/db";

dayjs.extend(advancedFormat);

export const getDateFromDayOfWeekType = (day: DayOfWeekType) => {
  const retval = dayjs()
    .day(
      [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ].indexOf(day),
    )
    .toDate();
  return retval;
};

/**
 * Returns a dayjs object with the time set to the startTime
 * @param startTime - 24 hour time string "HH:mm"
 * @returns dayjs object with the time set to the startTime
 */
export function createDayjs(startTime: string, day?: Date) {
  try {
    if (!startTime) {
      return dayjs();
    }
    const timePattern = /^(\d{2}):(\d{2})$/;
    const match = startTime.match(timePattern);

    if (!match) {
      throw new Error("Invalid time format. Expected HH:mm. Got: " + startTime);
    }

    const [_, hourStr, minuteStr] = match;
    const hour = parseInt(hourStr ?? "", 10);
    const minute = parseInt(minuteStr ?? "", 10);

    return dayjs(day).startOf("day").add(hour, "hour").add(minute, "minute");
  } catch (e) {
    console.error(e);
    return dayjs();
  }
}
