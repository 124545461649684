import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import {
  faChevronLeft,
  faPlus,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ProtocolBenefits } from "@acme/ui/app/protocol-benefits";
import { Button } from "@acme/ui/button";

import { cn } from "~/lib/utils";
import type { BaseProtocolWithExpert } from "~/store/calendar";
import {
  useIsFetchingProtocols,
  useProtocolStore,
  useSideDrawerStore,
} from "~/store/calendar";
import { api } from "~/utils/api";
import { LoadingSpinner } from "../core/Loading";
import { ProtocolCard, ProtocolCardPlaceholder } from "./ProtocolCard";

const MyProtocolStack = (props: { className?: string }) => {
  const { setMode } = useSideDrawerStore();
  const { data } = api.healthCategory.getTotalBenefits.useQuery();
  const [myBaseProtocols, setMyBaseProtocols] = useState<
    BaseProtocolWithExpert[]
  >([]);
  const isFetching = useIsFetchingProtocols();
  const {
    baseProtocols,
    userProtocols,
    protocols,
    getImplementedVariant,
    getImplementedBaseProtocols,
  } = useProtocolStore();

  useEffect(() => {
    setMyBaseProtocols(getImplementedBaseProtocols());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProtocols, baseProtocols, protocols]);

  const goToDetails = (baseProtocolId: string) => {
    const variant = getImplementedVariant(baseProtocolId);
    useProtocolStore.setState({
      focusedBaseProtocolId: baseProtocolId,
      focusedProtocolId: variant?.id,
    });
    setMode("protocol-customization");
  };

  return (
    <div className={cn("flex grow flex-col gap-8", props.className)}>
      <Title />
      <div className="flex flex-col gap-2">
        {myBaseProtocols.map((protocol) => {
          return (
            <ProtocolCard
              size="sm"
              key={protocol.id}
              protocol={protocol}
              expert={protocol.expert}
              onClick={() => goToDetails(protocol.id)}
            />
          );
        })}
        {isFetching && (
          <>
            <ProtocolCardPlaceholder />
            <ProtocolCardPlaceholder />
            <ProtocolCardPlaceholder />
          </>
        )}
        <AddNewProtocolItem />
      </div>
      <hr />
      <ProtocolBenefits
        // this is needed for XL screens... need to find out why health category bar spacing is different on XL screens
        className="max-w-[625px] max-md:-mx-4 max-md:w-screen max-md:rounded-none"
        healthGroups={data?.healthGroups ?? []}
        hasMultipleDataSets={true}
      />
    </div>
  );
};

const Title = () => {
  const isFetching = useIsFetchingProtocols();
  const { setMode } = useSideDrawerStore();

  return (
    <>
      <div className="col-span-2 hidden items-center justify-between md:flex">
        <div className="flex items-center gap-4">
          <h1 data-test="my-protocols-title" className="text-3xl font-semibold">
            Stack
          </h1>
          {isFetching && <LoadingSpinner size={20} />}
        </div>
        <Button
          size="icon"
          leftIcon={faTimes}
          variant={"ghost"}
          onClick={() => setMode("closed")}
        />
      </div>
      <div className="sticky top-0 z-10 -mx-4 flex items-center justify-between border-b bg-base-100 p-4 md:hidden">
        <Button
          size="icon"
          leftIcon={faChevronLeft}
          className="absolute left-2"
          variant={"ghost"}
          onClick={() => setMode("closed")}
        />
        <h1 className="mx-auto text-3xl font-semibold">Stack</h1>
      </div>
    </>
  );
};

const AddNewProtocolItem = () => {
  const router = useRouter();

  return (
    <button
      className="group flex items-center gap-3 rounded-xl p-2 transition-all duration-200 hover:bg-base-200"
      onClick={() => router.push("/search")}
    >
      <FontAwesomeIcon
        icon={faPlus}
        className="rounded-lg border p-4 text-base-content transition-all duration-200 group-hover:bg-base-100"
      />
      Add protocol
    </button>
  );
};

export { MyProtocolStack };
