import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAppleWhole,
  faAvocado,
  faBacon,
  faBath,
  faBed,
  faBlender,
  faBook,
  faBowlFood,
  faBowlRice,
  faBowlSoftServe,
  faBrain,
  faBurger,
  faBurgerSoda,
  faCakeSlice,
  faCapsules,
  faCarrot,
  faCheese,
  faChestnut,
  faClock,
  faCupStraw,
  faDrumstickBite,
  faDumbbell,
  faEgg,
  faEggFried,
  faEggplant,
  faEye,
  faFaceSleeping,
  faFaceSmileBeam,
  faFeatherAlt,
  faFire,
  faFishCooked,
  faGaugeSimple,
  faGaugeSimpleHigh,
  faGaugeSimpleLow,
  faGaugeSimpleMed,
  faGlassWater,
  faHammer,
  faHandBackFist,
  faHandHoldingMedical,
  faHands,
  faHeartPulse,
  faHoneyPot,
  faHotTub,
  faIgloo,
  faJar,
  faLayerGroup,
  faLeaf,
  faMountainSun,
  faMugSaucer,
  faOm,
  faPersonBiking,
  faPersonRunning,
  faPersonSwimming,
  faPersonWalking,
  faPotato,
  faSalad,
  faShower,
  faShrimp,
  faSnooze,
  faSoccerBall,
  faSpa,
  faSteak,
  faStrawberry,
  faSun,
  faSunrise,
  faSunset,
  faTaco,
  faTurkey,
  faUtensils,
  faWalking,
  faWater,
  faWind,
} from "@fortawesome/pro-solid-svg-icons";

const activityIcons = {
  "apple-whole": faAppleWhole,
  avocado: faAvocado,
  bacon: faBacon,
  bath: faBath,
  bed: faBed,
  blender: faBlender,
  book: faBook,
  "bowl-rice": faBowlRice,
  "bowl-food": faBowlFood,
  "bowl-soft-serve": faBowlSoftServe,
  brain: faBrain,
  burger: faBurger,
  "burger-soda": faBurgerSoda,
  "cake-slice": faCakeSlice,
  carrot: faCarrot,
  cheese: faCheese,
  chestnut: faChestnut,
  clock: faClock,
  capsules: faCapsules,
  "cup-straw": faCupStraw,
  "drumstick-bite": faDrumstickBite,
  dumbbell: faDumbbell,
  egg: faEgg,
  "egg-fried": faEggFried,
  eggplant: faEggplant,
  eye: faEye,
  "face-sleeping": faFaceSleeping,
  "face-smile-beam": faFaceSmileBeam,
  "feather-alt": faFeatherAlt,
  fire: faFire,
  "fish-cooked": faFishCooked,
  "gauge-simple": faGaugeSimple,
  "gauge-simple-high": faGaugeSimpleHigh,
  "gauge-simple-low": faGaugeSimpleLow,
  "gauge-simple-med": faGaugeSimpleMed,
  "glass-water": faGlassWater,
  hammer: faHammer,
  hands: faHands,
  "hand-back-fist": faHandBackFist,
  "hand-holding-medical": faHandHoldingMedical,
  "heart-pulse": faHeartPulse,
  "hot-tub": faHotTub,
  "honey-pot": faHoneyPot,
  igloo: faIgloo,
  jar: faJar,
  "layer-group": faLayerGroup,
  leaf: faLeaf,
  "mountain-sun": faMountainSun,
  "mug-saucer": faMugSaucer,
  om: faOm,
  "person-biking": faPersonBiking,
  "person-running": faPersonRunning,
  "person-swimming": faPersonSwimming,
  "person-walking": faPersonWalking,
  potato: faPotato,
  salad: faSalad,
  shower: faShower,
  shrimp: faShrimp,
  snooze: faSnooze,
  spa: faSpa,
  "soccer-ball": faSoccerBall,
  steak: faSteak,
  strawberry: faStrawberry,
  sun: faSun,
  sunrise: faSunrise,
  sunset: faSunset,
  taco: faTaco,
  turkey: faTurkey,
  utensils: faUtensils,
  walking: faWalking,
  water: faWater,
  wind: faWind,
} as const;

const activityIconNames = Object.keys(
  activityIcons,
) as (keyof typeof activityIcons)[];
const activityIconDefinitions = Object.values(activityIcons);

const initIconLibrary = () => library.add(...activityIconDefinitions);

export type ActivityIconName = (typeof activityIconNames)[number];

export {
  activityIconDefinitions,
  activityIconNames,
  activityIcons,
  initIconLibrary,
};
