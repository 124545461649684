import dayjs from "dayjs";
import { create } from "zustand";

import type {
  Activity,
  ActivityEvent,
  ActivitySchedule,
  ActivityStep,
  BaseProtocol,
  DayOfWeek,
  DayOfWeekType,
  Pillar,
  PillarType,
  Protocol,
  ProtocolType,
  UserProtocol,
} from "@acme/db";

export * from "./dates";
export * from "./hooks";
export * from "./sideDrawer";

export type BaseProtocolWithExpert = BaseProtocol & {
  expert: {
    name: string;
    image: string;
    subtitle: string;
    description: string;
  };
  pillar: PillarType;
  protocolType: ProtocolType;
};
export interface ProtocolStoreState {
  // state
  focusedActivityId: string | null;
  focusedBaseProtocolId: string | null;
  focusedProtocolId: string | null;
  activities: Record<
    string,
    Activity & {
      defaultDays: DayOfWeek[];
      steps: ActivityStep[];
    }
  >;
  activitySchedules: Record<string, ActivitySchedule>;
  baseProtocols: Record<string, BaseProtocolWithExpert>;
  protocols: Record<string, Protocol>;
  protocolTypes: Record<string, ProtocolType>;
  pillars: Record<string, Pillar>;
  userProtocols: Record<string, UserProtocol>;
  activityEvents: Record<string, ActivityEvent>;

  // client state (unsaved, client side data only)
  stagedActivitySchedules: Record<string, ActivitySchedule>;
  stagedUserProtocols: Record<string, UserProtocol>;

  // getters (derived state)
  getFocusedUserProtocol: () => UserProtocol | undefined;
  getFocusedSchedules: () => ActivitySchedule[];
  getFocusedActivities: () => (Activity & { defaultDays: DayOfWeek[] })[];
  getImplementedVariant: (baseProtocolId: string) => Protocol | undefined;
  getImplementedBaseProtocols: () => BaseProtocolWithExpert[];
  getActivitySchedulesForDay: (day: DayOfWeekType) => ActivitySchedule[];
  getActivityEventsForDay: (day: Date) => ActivityEvent[];
  getHasUnsavedChanges: () => boolean;

  // actions
  upsertSchedule: (
    schedule: ActivitySchedule,
    opts?: { autosetSimilarSchedules: boolean },
  ) => void;
  resetFocusedSchedules: () => void;
  resetAllSchedules: () => void;
  /**
   * Generates schedules for the default days of activities in a protocol
   * @param userProtocolId - the id of the user protocol to generate schedules for
   * @returns void
   */
  generateInitialSchedules: (args: {
    protocolId: string;
    userProtocolId?: string;
  }) => void;
}

export const useProtocolStore = create<ProtocolStoreState>((_set, _get) => ({
  //
  // state
  //
  focusedActivityId: null,
  focusedBaseProtocolId: null,
  focusedProtocolId: null,
  activities: {},
  activityEvents: {},
  activitySchedules: {},
  pillars: {},
  protocolTypes: {},
  baseProtocols: {},
  protocols: {},
  userProtocols: {},

  //
  // client state (unsaved, client side data only)
  //
  stagedActivitySchedules: {},
  stagedUserProtocols: {},

  // getters
  getFocusedUserProtocol: () => {
    const { focusedProtocolId, userProtocols, stagedUserProtocols } = _get();
    const allUserProtocols = { ...userProtocols, ...stagedUserProtocols };
    return Object.values(allUserProtocols).find(
      (userProtocol) => userProtocol.protocolId === focusedProtocolId,
    );
  },
  getFocusedSchedules: () => {
    const {
      activitySchedules,
      stagedActivitySchedules,
      getFocusedUserProtocol,
    } = _get();
    const focusedUserProtocol = getFocusedUserProtocol();

    const allSchedules = { ...activitySchedules, ...stagedActivitySchedules };
    return Object.values(allSchedules).filter(
      (sched) => sched.userProtocolId === focusedUserProtocol?.id,
    );
  },
  getFocusedActivities: () => {
    const { focusedProtocolId, activities } = _get();
    return Object.values(activities).filter(
      (act) => act.protocolId === focusedProtocolId,
    );
  },
  getImplementedVariant: (baseProtocolId: string) => {
    const { protocols, userProtocols } = _get();
    const variants = Object.values(protocols).filter(
      (protocol) => protocol.baseProtocolId === baseProtocolId,
    );
    const implementedVariantIds = Object.values(userProtocols).map(
      ({ protocolId }) => protocolId,
    );
    const implementedVariant = variants.find((variant) =>
      implementedVariantIds.includes(variant.id),
    );
    return implementedVariant;
  },
  getImplementedBaseProtocols: () => {
    const { userProtocols, protocols, baseProtocols } = _get();
    const userProtocolIds = Object.values(userProtocols).map(
      ({ protocolId }) => protocolId,
    );
    const baseProtocolIds = Object.values(protocols)
      .filter((protocol) => userProtocolIds.includes(protocol.id))
      .map((protocol) => protocol.baseProtocolId);

    const filteredBaseProtocols = Object.values(baseProtocols).filter(
      (protocol) => baseProtocolIds.includes(protocol.id),
    );

    return filteredBaseProtocols;
  },

  getActivitySchedulesForDay: (day: DayOfWeekType) => {
    const {
      userProtocols,
      stagedUserProtocols,
      stagedActivitySchedules,
      protocols,
      activities,
      activitySchedules,
      focusedBaseProtocolId,
      focusedProtocolId,
    } = _get();
    const activitiesForDay: Activity[] = [];
    const focusedProtocol = focusedProtocolId
      ? protocols[focusedProtocolId]
      : null;
    const myProtocols = Object.values({
      ...userProtocols,
      ...stagedUserProtocols,
    }).map((userProtocol) => protocols[userProtocol.protocolId]);

    myProtocols.forEach((protocol) => {
      if (!protocol) throw new Error("Protocol not found");
      const acts = Object.values(activities).filter(
        (act) => act.protocolId === protocol.id,
      );
      if (protocol.baseProtocolId !== focusedBaseProtocolId) {
        activitiesForDay.push(...acts);
      } else if (protocol.id === focusedProtocolId) {
        activitiesForDay.push(...acts);
      } else if (focusedProtocol?.baseProtocolId === protocol.baseProtocolId) {
        const focusedActs = Object.values(activities).filter(
          (act) => act.protocolId === focusedProtocolId,
        );
        activitiesForDay.push(...focusedActs);
      }
    });

    const schedules = activitiesForDay.flatMap((act) => {
      const schedules = Object.values({
        ...activitySchedules,
        ...stagedActivitySchedules,
      }).filter((sched) => {
        return sched?.activityId === act.id && sched.dayOfWeek === day;
      });
      return schedules;
    });

    // for some reason, we're getting duplicate schedules. right now this is the easiest way to dedupe them
    // and the behavior seems to be correct. If funky things start happening, this is a good place to look.
    const uniqueSchedulesById = schedules.reduce((acc, schedule) => {
      const id = schedule?.id;
      if (!id) return acc;
      return { ...acc, [id]: schedule };
    }, {});

    return Object.values(uniqueSchedulesById);
  },
  getActivityEventsForDay: (day: Date) => {
    const { activityEvents } = _get();
    const dayEvents = Object.values(activityEvents).filter((event) => {
      return dayjs(event.scheduledFor).isSame(day, "day");
    });
    return dayEvents;
  },
  getHasUnsavedChanges: () => {
    const { stagedActivitySchedules, activitySchedules } = _get();

    const hasUnsavedChanges = Object.values(stagedActivitySchedules).some(
      (stagedSchedule) => {
        const existingSchedule = activitySchedules[stagedSchedule.id];
        if (!stagedSchedule.startTime) return false;
        if (!existingSchedule) return true;
        return (
          stagedSchedule.dayOfWeek !== existingSchedule.dayOfWeek ||
          stagedSchedule.startTime !== existingSchedule.startTime
        );
      },
    );

    return hasUnsavedChanges;
  },

  //
  // actions
  //
  upsertSchedule: (
    data: ActivitySchedule,
    options?: { autosetSimilarSchedules: boolean },
  ) => {
    _set((storeState) => {
      let newState: ProtocolStoreState;
      const userProtocolId = Object.values({
        ...storeState.userProtocols,
        ...storeState.stagedUserProtocols,
      }).find((up) => up.protocolId === storeState.focusedProtocolId)?.id;

      if (userProtocolId && data.id) {
        // update existing schedule
        const existingSchedule =
          storeState.stagedActivitySchedules[data.id] ??
          storeState.activitySchedules[data.id];

        newState = {
          ...storeState,
          stagedActivitySchedules: {
            ...storeState.stagedActivitySchedules,
            [data.id]: { ...existingSchedule, ...data },
          },
        };
      } else if (userProtocolId && !data.id) {
        // create new schedule
        const newActivityScheduleId = crypto.randomUUID();
        newState = {
          ...storeState,
          stagedActivitySchedules: {
            ...storeState.stagedActivitySchedules,
            [newActivityScheduleId]: {
              ...data,
              userProtocolId,
              id: newActivityScheduleId,
            },
          },
        };
      } else {
        throw new Error(
          "No user protocol found for focused protocol. Cannot save schedule.",
        );
      }

      let otherDaySchedules = {};
      if (options?.autosetSimilarSchedules) {
        otherDaySchedules = generateSchedulesForOtherDays({
          ...data,
          userProtocolId,
        });
      }

      return {
        ...newState,
        stagedActivitySchedules: {
          ...newState.stagedActivitySchedules,
          ...otherDaySchedules,
        },
      };
    });
  },
  resetFocusedSchedules: () => {
    const {
      userProtocols,
      getFocusedUserProtocol,
      getFocusedSchedules,
      stagedActivitySchedules,
      generateInitialSchedules,
      getFocusedActivities,
    } = _get();

    const focusedUserProtocol = getFocusedUserProtocol();
    const focusedActivities = getFocusedActivities();
    const focusedScheds = getFocusedSchedules();

    if (!focusedUserProtocol) throw new Error("No focused user protocol found");

    const updatedActivitySchedules = { ...stagedActivitySchedules };

    focusedScheds.forEach((focusedSchedule) => {
      delete updatedActivitySchedules[focusedSchedule.id];
    });

    _set((state) => ({
      ...state,
      stagedActivitySchedules: updatedActivitySchedules,
    }));

    const isImplemented = Object.values(userProtocols).some(
      (up) => up.id === focusedUserProtocol?.id,
    );

    if (!isImplemented) {
      generateInitialSchedules({
        protocolId: focusedUserProtocol?.protocolId ?? "",
        userProtocolId: focusedUserProtocol?.id,
      });
    } else {
      focusedActivities.forEach((activity) => {
        const schedsForThisActivity = focusedScheds.filter(
          (sched) => sched.activityId === activity.id,
        );

        activity?.defaultDays?.forEach((defaultDay, i) => {
          const sched = schedsForThisActivity[i];
          if (!sched) throw new Error("No schedule found for activity");
          updatedActivitySchedules[sched.id] = {
            ...sched,
            // dayOfWeek: defaultDay.name,
            startTime: "",
          };
        });
      });

      _set((state) => ({
        ...state,
        stagedActivitySchedules: updatedActivitySchedules,
      }));
    }
  },
  resetAllSchedules: () => {
    _set((storeState) => {
      return {
        ...storeState,
        stagedUserProtocols: {},
        stagedActivitySchedules: {},
      };
    });
  },
  generateInitialSchedules: (args: {
    protocolId: string;
    userProtocolId?: string;
  }) => {
    _set((storeState) => {
      const schedules: ActivitySchedule[] = [];
      const userProtocolId = args.userProtocolId ?? crypto.randomUUID();

      const { protocols, activities, focusedProtocolId } = _get();
      const protocol = protocols[args.protocolId];

      if (!protocol) throw new Error("Protocol not found");
      if (!focusedProtocolId) throw new Error("No focused protocol found");
      const activitiesForProtocol = Object.values(activities).filter(
        (act) => act.protocolId === protocol.id,
      );

      activitiesForProtocol.forEach((activity) => {
        activity.defaultDays.forEach((defaultDay) => {
          const newActivityScheduleId = crypto.randomUUID();
          schedules.push({
            userProtocolId,
            activityId: activity.id,
            dayOfWeek: defaultDay.name,
            startTime: "",
            id: newActivityScheduleId,
            createdAt: new Date(),
          });
        });
      });

      return {
        ...storeState,
        stagedUserProtocols: {
          ...storeState.stagedUserProtocols,
          [userProtocolId]: {
            id: userProtocolId,
            protocolId: focusedProtocolId,
            createdAt: new Date(),
            updatedAt: new Date(),
            archivedAt: null,
            isImplemented: false,
            userId: "userId", // this is a hack... ideally we can access the user id from the auth context
          },
        },
        stagedActivitySchedules: {
          ...storeState.stagedActivitySchedules,
          ...schedules.reduce(
            (acc, sched) => ({ ...acc, [sched.id]: sched }),
            {},
          ),
        },
      };
    });
  },
}));

/**
 * Generates schedules for the default days of an activity that don't already have
 * a schedule for that activity, ensuring that no schedules share the same startTime
 * on the same day (and instead default to an empty startTime)
 * @param inputSchedule - the schedule to generate other schedules for
 * @returns an object of schedules keyed by their id
 */
const generateSchedulesForOtherDays = (inputSchedule: ActivitySchedule) => {
  const { startTime, userProtocolId } = inputSchedule;
  const { stagedActivitySchedules } = useProtocolStore.getState();
  const stagedSchedulesForOtherDays: Record<string, ActivitySchedule> = {};

  // Collect all start times for schedules on the same day to prevent duplicates
  const startTimesForDay = new Map<string, string>();

  startTimesForDay.set(inputSchedule.dayOfWeek, startTime);

  Object.values(stagedActivitySchedules)
    .filter((sched) => sched.userProtocolId === userProtocolId)
    .forEach((sched, _, all) => {
      const matchingActivitySchedule = all.find(
        (s) => s.activityId === sched.activityId && s.id !== sched.id,
      );
      if (
        matchingActivitySchedule &&
        sched.activityId !== inputSchedule.activityId
      ) {
        return;
      }
      // If there's already a startTime for this day and it matches the input startTime, skip
      if (startTimesForDay.get(sched.dayOfWeek) === startTime) {
        return;
      }

      // If this schedule's startTime is empty and it's not already set to the input startTime for this day, assign it
      if (sched.startTime === "") {
        stagedSchedulesForOtherDays[sched.id] = { ...sched, startTime };
        startTimesForDay.set(sched.dayOfWeek, startTime);
      }
    });

  return stagedSchedulesForOtherDays;
};
