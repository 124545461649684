import React from "react";
import Head from "next/head";

import { useTheme } from "~/providers/theme";

interface SEOProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
}

export function SEO({
  title = "Vital (Beta)",
  description = "Achieve vitality through preventative health",
  children,
}: SEOProps) {
  const resolvedTitle = title ? `${title} | Vital` : "Vital";
  const { resolvedTheme } = useTheme();

  return (
    <Head>
      <title>{resolvedTitle}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/dl7p14ddo/image/upload/v1684894255/vital/vital-og-image_eu3noh.png"
      />
      <meta property="og:url" content="https://beta.vitalroutines.ai" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href="https://beta.vitalroutines.ai" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta
        name="theme-color"
        content={resolvedTheme === "dark" ? "hsl(24, 10%, 10%)" : "#fff"}
      />
      {children}
    </Head>
  );
}
