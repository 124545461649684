import React, { useCallback, useEffect, useRef, useState } from "react";
import { TooltipContent, TooltipPortal } from "@radix-ui/react-tooltip";

import type { PillarType } from "@acme/db";

import type { DataPoint } from ".";
import { pillarBackgroundColors } from "../../../components/custom/utils";
import { cn, useBreakpoint } from "../../../lib/utils";
import { Tooltip, TooltipProvider, TooltipTrigger } from "../../ui/tooltip";
import { CustomTooltip } from "./Tooltip";

interface ScoreBadgeProps {
  value: string | number;
  pillar?: PillarType;
  className?: string;
}

export const ScoreBadge: React.FC<ScoreBadgeProps> = (props) => {
  const { value, pillar, className } = props;
  return (
    <div
      className={cn(
        "flex h-[26px] w-fit items-center justify-center whitespace-nowrap rounded-md px-2 text-sm text-base-placeholder",
        pillar
          ? pillarBackgroundColors[pillar]
          : "border border-base-placeholder",
        className,
      )}
    >
      {value}
    </div>
  );
};

interface EntryType {
  payload: { value: string };
  x: number;
  y: number;
  cx: number;
  cy: number;
  index: number;
}
const getCustomPlacements = (
  data: DataPoint[],
  chartWidth: number,
  chartHeight: number,
  labelWidth: number,
  labelHeight: number,
) => {
  const placements = [];

  if (data.length === 1) {
    placements.push({ x: chartWidth / 2 - labelWidth / 2, y: 10 });
  } else if (data.length === 2) {
    placements.push({
      x: chartWidth - labelWidth,
      y: chartHeight / 2 - labelHeight / 2,
    });
    placements.push({
      x: 0,
      y: chartHeight / 2 - labelHeight / 2,
    });
  } else if (data.length === 3) {
    placements.push({
      x: chartWidth / 2 - labelWidth / 2,
      y: 0,
    });
    placements.push({ x: 10, y: chartHeight - labelHeight * 1.5 });
    placements.push({
      x: chartWidth - labelWidth,
      y: chartHeight - labelHeight * 1.5,
    });
  } else if (data.length === 4) {
    placements.push({
      x: chartWidth / 2 - labelWidth / 2,
      y: 0,
    });
    placements.push({
      x: chartWidth - labelWidth,
      y: chartHeight / 2 - labelHeight / 2,
    });
    placements.push({
      x: chartWidth / 2 - labelWidth / 2,
      y: chartHeight - labelHeight,
    });
    placements.push({
      x: 0,
      y: chartHeight / 2 - labelHeight / 2,
    });
  } else if (data.length === 5) {
    placements.push({
      x: chartWidth / 2 - labelWidth / 2,
      y: 0,
    });
    placements.push({
      x: chartWidth - labelWidth,
      y: chartHeight / 2 - labelHeight,
    });
    placements.push({
      x: chartWidth - labelWidth - 10,
      y: (2 * chartHeight) / 3,
    });
    placements.push({
      x: 10,
      y: (2 * chartHeight) / 3,
    });
    placements.push({
      x: 0,
      y: chartHeight / 2 - labelHeight,
    });
  } else if (data.length === 6) {
    placements.push({
      x: chartWidth / 2 - labelWidth / 2,
      y: 0,
    });
    placements.push({ x: chartWidth - labelWidth, y: labelHeight });
    placements.push({
      x: chartWidth - labelWidth,
      y: chartHeight - 2 * labelHeight,
    });
    placements.push({
      x: chartWidth / 2 - labelWidth / 2,
      y: chartHeight - labelHeight,
    });

    placements.push({ x: 0, y: chartHeight - 2 * labelHeight });
    placements.push({
      x: 0,
      y: labelHeight,
    });
  }

  return placements;
};
export const renderCustomLabel =
  (
    data: DataPoint[],
    chartWidth: number,
    chartHeight: number,
    size: "sm" | "lg",
    showTotal?: boolean,
  ) =>
  (entry: EntryType) => {
    const { index } = entry;
    const labelWidth = size === "sm" ? 100 : 150;
    const labelHeight = size === "sm" ? 90 : 100;
    const placements = getCustomPlacements(
      data,
      chartWidth,
      chartHeight,
      labelWidth,
      labelHeight,
    );
    const { x, y } = placements[index] ?? {
      x: 10,
      y: 10,
    };

    const dataPoint = data[index]!;

    if (data.length === 1 && index !== 0) {
      return <g />;
    }

    if (data.length === 2 && index > 1) {
      return <g />;
    }

    const value = dataPoint.values[0]!;
    const total =
      dataPoint.values.reduce((acc, v) => acc + v.value, 0).toString() +
        " / 10" ?? "0 / 10";

    return (
      <g>
        <foreignObject x={x} y={y} width={labelWidth} height={labelHeight}>
          <CustomLabel
            label={dataPoint.label}
            description={value.description}
            value={showTotal ? total : value.value}
            pillar={showTotal ? undefined : value.pillar}
          />
        </foreignObject>
      </g>
    );
  };

export interface CustomLabelProps {
  label: string;
  pillar?: PillarType;
  description?: string;
  value: number | string;
}

const CustomLabel = (props: CustomLabelProps) => {
  const { pillar, label, value, description } = props;
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const isDesktop = useBreakpoint("md");

  const handleClick = useCallback(() => {
    setIsTooltipOpen(!isTooltipOpen);
  }, [isTooltipOpen]);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setIsTooltipOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <TooltipProvider delayDuration={0} disableHoverableContent>
      <Tooltip open={isTooltipOpen}>
        <TooltipTrigger asChild>
          <div
            className="relative mx-auto flex h-[100px] w-fit flex-col items-start justify-center gap-2 truncate text-center"
            onClick={handleClick}
            onMouseEnter={() => (isDesktop ? setIsTooltipOpen(true) : null)}
            onMouseLeave={() => (isDesktop ? setIsTooltipOpen(false) : null)}
            ref={tooltipRef}
          >
            <div className="flex flex-row flex-wrap gap-1">
              <ScoreBadge pillar={pillar} value={value} />
            </div>
            <div className="w-full truncate text-wrap text-left text-sm font-semibold text-base-placeholder max-md:max-w-[100px] max-md:text-xs">
              {label}
            </div>
          </div>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent className="z-40">
            <CustomTooltip
              value={value}
              pillar={pillar}
              description={description}
              label={label}
            />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};
