import React, { createContext, useContext, useState } from "react";
import type { ImageProps } from "next/image";
import Image from "next/image";
import { faCircle, faPlay, faUndo } from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faCheckCircle,
  faChevronRight,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import dayjs from "dayjs";
import type { MotionProps } from "framer-motion";

import type {
  Activity,
  ActivityEvent as ActivityEventType,
  ActivityStep,
  BaseProtocol,
  Expert,
} from "@acme/db";

import { cn } from "../../lib/utils";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import type { CarouselApi } from "../ui/carousel";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselStepIndicator,
} from "../ui/carousel";
import type { ActivityIconName } from "../ui/icon";
import { Icon } from "../ui/icon";
import { ScrollArea } from "../ui/scroll-area";
import { pillarColors } from "./utils";

interface ComponentDataModels {
  isPreview?: boolean;
  event?: Pick<ActivityEventType, "completedAt"> & {
    scheduledFor: Date | null;
  };
  activity: Pick<Activity, "icon" | "pillar" | "name" | "minutes"> & {
    steps: ActivityStep[];
  };
  protocol: Pick<BaseProtocol, "name">;
  expert: Pick<Expert, "name" | "image">;
}

export interface ActivityEventProps extends ComponentDataModels {
  className?: string;
  onComplete?: () => Promise<void> | void;
  onProtocolClick?: () => void;
  onClose?: () => void;
}

type EventStatus = "complete" | "incomplete" | "in-progress";

interface ActivityEventContext extends ActivityEventProps {
  stepIndex: number;
  status: EventStatus;
  setStepIndex: (index: number) => void;
  setStatus: (status: EventStatus) => void;
}

const ActivityEvent = React.forwardRef<HTMLDivElement, ActivityEventProps>(
  (props, ref) => {
    const {
      className,
      onProtocolClick,
      isPreview,
      onComplete,
      activity,
      expert,
      event,
      protocol,
      ...rest
    } = props;
    const [status, setStatus] = React.useState<EventStatus>(
      props.event?.completedAt ? "complete" : "incomplete",
    );
    const [stepIndex, setStepIndex] = React.useState<number>(0);

    return (
      <ActivityEventContext.Provider
        value={{
          status,
          activity,
          event,
          protocol,
          expert,
          setStatus,
          stepIndex,
          setStepIndex,
          ...rest,
        }}
      >
        <div
          ref={ref}
          {...rest}
          className={cn(
            "flex min-w-0 flex-col items-stretch max-md:h-dvh",
            className,
          )}
        >
          {status !== "in-progress" && <EventSummary />}
          {status === "in-progress" && <StepContainer />}
        </div>
      </ActivityEventContext.Provider>
    );
  },
);
ActivityEvent.displayName = "ActivityEvent";

const ActivityEventHeader = () => {
  const { activity } = useActivityEventContext();
  return (
    <div className="flex flex-row items-center justify-between gap-4 text-2xl font-semibold">
      <div className="flex items-center gap-2">
        <Icon
          icon={(activity.icon as ActivityIconName) ?? "running"}
          className={cn(pillarColors[activity.pillar], "text-2xl")}
        />
        {activity.name}
      </div>
      <div className="flex gap-2">
        <CloseButton />
      </div>
    </div>
  );
};

interface BackdropImageProps extends MotionProps {
  src: ImageProps["src"];
  children?: React.ReactNode;
  opacity?: number;
  className?: string;
}
const BackdropImage: React.FC<BackdropImageProps> = (props) => {
  const { children, src, opacity = 0.2 } = props; // Default opacity to 0.5
  const [hasLoaded, setHasLoaded] = useState(false);

  return (
    <div
      className={cn("relative aspect-square overflow-clip", props.className)}
    >
      <Image
        width={500}
        height={500}
        src={src}
        alt=""
        onLoad={() => setHasLoaded(true)}
        className={cn(
          !hasLoaded && "opacity-0",
          "absolute z-0 aspect-square h-auto w-full bg-base-100 object-cover object-center transition-opacity duration-300 ease-in-out",
        )}
      />
      <div
        className="absolute z-10 h-full w-full"
        style={{ backgroundColor: `rgba(0, 0, 0, ${opacity})` }}
      ></div>
      <div className="relative z-20 flex w-full p-4 text-white  md:p-6">
        {children}
      </div>
    </div>
  );
};

const EventSummary = () => {
  const { activity, event, setStatus, status, onComplete, isPreview } =
    useActivityEventContext();
  const steps = activity.steps ?? [];
  const firstImage = `/activity-steps/${activity.steps?.[0]?.image}`;

  const minutes = activity.minutes % 60;
  const hours = Math.floor(activity.minutes / 60);
  const date = dayjs(event?.scheduledFor ?? new Date()).format("MMM D YYYY");
  const startTime = event?.scheduledFor
    ? dayjs(event?.scheduledFor).format("h:mm A")
    : null;

  return (
    <div className="flex flex-col gap-6 p-6">
      <ActivityEventHeader />
      <div className="flex flex-col gap-4">
        <div className="flex gap-2">
          {startTime ? <Badge variant="subtle">{startTime}</Badge> : null}
          <Badge variant="outline" className="text-base-content">
            <div className="flex gap-1.5">
              {hours > 0 && (
                <div className="flex gap-1">
                  <span>{hours}</span>
                  <span className="font-thin">hr</span>
                </div>
              )}
              {minutes > 0 && (
                <div className="flex gap-1">
                  {hours > 0 && " "}
                  <span>{minutes}</span>
                  <span className="font-thin">min</span>
                </div>
              )}
            </div>
          </Badge>
          <Badge variant="outline" className="border-none">
            {date}
          </Badge>
        </div>
      </div>
      <BackdropImage
        opacity={0.5}
        src={firstImage}
        className="flex rounded-xl"
        variants={status === "complete" ? contractAnimation : expandAnimation}
      >
        <div className="z-10 flex flex-col gap-3">
          <p>Overview</p>
          <ol className="list-inside list-decimal space-y-2 pl-2 marker:font-extralight">
            {steps.map((step, index) => (
              <li key={index}>
                <span className="ml-2">{step.name}</span>
              </li>
            ))}
          </ol>
        </div>
      </BackdropImage>
      <ProtocolCard />
      <div className="flex max-w-[100vw] justify-end gap-2">
        <Button
          leftIcon={status === "complete" ? faCheckCircle : faCircle}
          onClick={async () => {
            await onComplete?.();
            setStatus(status === "complete" ? "incomplete" : "complete");
          }}
          className={cn(
            status !== "complete" && "text-base-placeholder",
            isPreview && "hidden",
          )}
          variant={"ghost"}
        >
          Complete
        </Button>
        <Button
          leftIcon={status === "complete" ? faUndo : faPlay}
          variant={status === "complete" ? "outline" : "default"}
          onClick={() => setStatus("in-progress")}
        >
          {isPreview
            ? "Preview activity"
            : status === "complete"
            ? "Restart activity"
            : "Start activity"}
        </Button>
      </div>
    </div>
  );
};

const StepContainer = () => {
  const {
    activity,
    setStatus,
    stepIndex,
    setStepIndex,
    onComplete,
    event,
    isPreview,
  } = useActivityEventContext();
  const [_api, setApi] = useState<CarouselApi>();
  const image = `/activity-steps/${activity.steps?.[stepIndex]?.image}`;

  const steps = activity.steps ?? [];
  const isOnLastStep = stepIndex === steps.length - 1;

  return (
    <Carousel
      onIndexChange={setStepIndex}
      setApi={setApi}
      className="-m-[1px] flex flex-col overflow-clip md:rounded-2xl"
    >
      <BackdropImage
        className=""
        src={image}
        opacity={0.3}
        variants={expandAnimation}
      >
        <div className="z-10 flex w-full flex-row items-center justify-between gap-4 text-2xl font-semibold">
          <h1 className="">{activity.name}</h1>
          <CloseButton
            onClick={() => setStatus("incomplete")}
            className="bg-white/20 text-base-100"
          />
        </div>
      </BackdropImage>
      <div className="flex flex-col justify-between bg-base-100 text-base-content">
        <CarouselContent className="flex flex-row gap-2">
          {steps.map((step, index) => (
            <CarouselItem key={index}>
              <ScrollArea
                style={{ maxHeight: 306, minHeight: 200 }}
                className="h-full"
              >
                <div className="p-6">
                  <h2 className="font-medium">{step.name}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: step.description ?? "" }}
                    className="prose-xs prose prose-sm whitespace-break-spaces text-base-content dark:prose-invert"
                  ></div>
                </div>
              </ScrollArea>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="mx-6 flex items-center justify-between border-t py-6">
          <CarouselStepIndicator />
          <div className="flex gap-2">
            <CarouselPrevious>{!isOnLastStep ? "Prev" : ""}</CarouselPrevious>
            <CarouselNext
              variant={isOnLastStep ? "default" : "subtle"}
              color={isOnLastStep ? "brand" : "default"}
              leftIcon={isOnLastStep ? faCheck : undefined}
              rightIcon={isOnLastStep ? undefined : faChevronRight}
              disabled={isOnLastStep ? false : undefined}
              onClick={
                isOnLastStep
                  ? async () => {
                      if (!event?.completedAt) {
                        await onComplete?.();
                      }
                      setStepIndex(0);
                      setStatus("complete");
                    }
                  : undefined
              }
            >
              {!isOnLastStep
                ? "Next"
                : isPreview
                ? "Finish"
                : "Mark as complete"}
            </CarouselNext>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

const ProtocolCard = () => {
  const { expert, protocol, onProtocolClick } = useActivityEventContext();

  return (
    <button
      onClick={(e) => {
        if (onProtocolClick) {
          e.stopPropagation();
          onProtocolClick();
        }
      }}
      className="flex flex-col items-start gap-2 rounded-xl p-3 transition-colors duration-200 hover:bg-base-200"
    >
      <div className="flex items-start gap-3 rounded-lg">
        <Image
          src={`/protocols/${protocol.name}.jpg`}
          alt={`Image of ${protocol.name}`}
          height={48}
          width={48}
          className="h-12 w-12 rounded-lg bg-center object-cover"
        />
        <div className="flex grow flex-col gap-1">
          <h3 className="truncate whitespace-nowrap text-left font-medium">
            {protocol.name}
          </h3>
          <div className="flex w-fit items-center gap-1.5">
            <h4 className="text-xs font-light text-base-placeholder">
              Inspired by
            </h4>
            <Image
              src={expert.image}
              alt={`Image of ${expert.name}`}
              width={32}
              height={32}
              className="h-5 w-5 rounded-full bg-center object-cover"
            />
            <h4 className="text-xs text-base-placeholder">{expert.name}</h4>
          </div>
        </div>
      </div>
    </button>
  );
};

const CloseButton = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => {
  const { onClose } = useActivityEventContext();
  return (
    <Button
      size="icon"
      variant="subtle"
      leftIcon={faTimes}
      ref={ref}
      className={cn("", className)}
      onClick={onClose}
      {...props}
    />
  );
});
CloseButton.displayName = Button.displayName;

export { ActivityEvent };

const expandAnimation = {
  initial: { top: "80px", scaleX: 0.9, scaleY: 0.9 },
  animate: { top: 0, scaleX: 1, scaleY: 1 },
  exit: { opacity: 0 },
};

const contractAnimation = {
  initial: { top: "-70px", scaleX: 1.1, scaleY: 1.1 },
  animate: { top: 0, scaleX: 1, scaleY: 1 },
  exit: { opacity: 0 },
};

const defaultState: ActivityEventContext = {
  status: "incomplete",
  stepIndex: 0,
  activity: {
    name: "Activity",
    icon: "running",
    minutes: 0,
    steps: [],
    pillar: "fitness",
  },
  protocol: {
    name: "",
  },
  expert: {
    name: "",
    image: "",
  },
  setStepIndex: () => {
    //
  },
  setStatus: () => {
    //
  },
};

const ActivityEventContext = createContext<ActivityEventContext>(defaultState);

export const useActivityEventContext = () => useContext(ActivityEventContext);
