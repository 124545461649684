// ValueLabel.tsx

import React from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";

import type { PillarType } from "@acme/db";
import { colors } from "@acme/tailwind-config";

import { getFillByPillar } from "../../../components/custom/utils";
import { useBreakpoint } from "../../../lib/utils";
import { renderCustomLabel } from "./CustomLabel";
import { CustomLegend } from "./Legend";

export interface DataPoint {
  /**
   * Signals if the radar chart has multiple data sets
   */
  hasMultipleDataSets?: boolean;
  label: string;
  description?: string;
  valueIndex?: number;
  values: {
    value: number;
    pillar: PillarType;
    description?: string;
    protocolName: string;
  }[];
}

interface CustomRadarChartProps {
  data: DataPoint[];
}

const CustomRadarChart = ({ data }: CustomRadarChartProps) => {
  const hasMultipleDataSets = data.some((d) => d.hasMultipleDataSets);
  const isDesktop = useBreakpoint();
  const [chartWidth, setChartWidth] = React.useState(0);
  const [chartHeight, setChartHeight] = React.useState(0);

  const transformedData = getTransformedData(data);
  const handleResize = React.useCallback(
    (containerWidth: number, containerHeight: number) => {
      setChartWidth(containerWidth);
      setChartHeight(containerHeight);
    },
    [],
  );

  const mappedData = transformedData.map((d) => {
    const mapped = d.values.reduce(
      (acc, v, index) => {
        acc[`value_${index + 1}`] = v.value;
        return acc;
      },
      {} as Record<string, number>,
    );
    return { label: d.label, ...mapped };
  });

  return (
    <>
      {hasMultipleDataSets && isDesktop && <CustomLegend data={data} />}
      <ResponsiveContainer
        onResize={handleResize}
        className={"mx-auto"}
        width="100%"
        height="100%"
      >
        <RadarChart
          cx="50%"
          cy="50%"
          outerRadius={isDesktop ? "60%" : "50%"}
          data={mappedData}
        >
          <PolarGrid
            gridType="polygon"
            stroke={colors["base-placeholder"]}
            strokeDasharray="5 5"
            radialLines={false}
          />
          <PolarAngleAxis
            dataKey="label"
            tick={renderCustomLabel(
              data,
              chartWidth,
              chartHeight,
              isDesktop ? "lg" : "sm",
              hasMultipleDataSets,
            )}
            axisLine={false}
            tickLine={false}
          />
          <PolarRadiusAxis
            domain={[0, 10]}
            axisLine={false}
            tick={false}
            tickCount={10}
          />
          {transformedData[0]?.values.map(({ pillar }, index) => (
            <Radar
              animationDuration={700}
              key={`radar-${index}`}
              dataKey={`value_${index + 1}`}
              stroke={getFillByPillar(pillar)}
              strokeWidth={3}
              strokeOpacity={1}
              fill={getFillByPillar(pillar)}
              fillOpacity={0.3}
            />
          ))}
        </RadarChart>
      </ResponsiveContainer>
    </>
  );
};

/**
 * Transforms and formats the input data for proper rendering in the radar chart.
 *
 * This function handles special cases when there are only one or two data points:
 * - For a single data point, it duplicates the data point 6 times and adds spaces to the labels
 *   for proper rendering and spacing.
 * - For two data points, it creates 6 data points by (3 for each incoming data point)
 *   ensuring that each data point has a corresponding value from the other data point.
 *   It also adds spaces to the labels for proper rendering and spacing.
 *
 * If there are more than two data points, the function simply returns the original data.
 *
 * Finally, if there are more than two data points passed in, the function sorts the transformed data
 * based on the magnitude of the values, in ascending order.
 *
 * @param data The input data for the radar chart
 * @returns The transformed and formatted data for rendering in the chart
 */
const getTransformedData = (data: DataPoint[]) => {
  const dataPointCount = data.length;

  const transformedData =
    dataPointCount === 1
      ? (Array(6).fill(data[0]) as DataPoint[]).map((d, index) => ({
          ...d,
          label: `${d.label}${" ".repeat(index)}`,
        }))
      : dataPointCount === 2
      ? ([
          {
            ...data[0],
            label: data[0]!.label,
            values: [...data[0]!.values, ...data[1]!.values],
            valueIndex: 0,
          },
          {
            ...data[0],
            label: data[0]!.label + " ",
            values: [
              ...data[0]!.values,
              ...data[0]!.values.map((v) => ({ ...v, value: 0 })),
            ],
            valueIndex: 0,
          },
          {
            ...data[0],
            label: data[0]!.label + "  ",
            values: [
              ...data[0]!.values,
              ...data[0]!.values.map((v) => ({ ...v, value: 0 })),
            ],
            valueIndex: 0,
          },
          {
            ...data[1],
            label: data[1]!.label,
            values: [...data[0]!.values, ...data[1]!.values],
            valueIndex: 1,
          },
          {
            ...data[1],
            label: data[1]!.label + " ",
            values: [
              ...data[1]!.values.map((v) => ({ ...v, value: 0 })),
              ...data[1]!.values,
            ],
            valueIndex: 1,
          },
          {
            ...data[1],
            label: data[1]!.label + "  ",
            values: [
              ...data[1]!.values.map((v) => ({ ...v, value: 0 })),
              ...data[1]!.values,
            ],
            valueIndex: 1,
          },
        ] as DataPoint[])
      : data;

  if (dataPointCount === 1 || dataPointCount === 2) {
    return transformedData;
  }

  return transformedData.sort((a, b) => {
    const aSum = a.values.reduce((acc, v) => acc + v.value, 0);
    const bSum = b.values.reduce((acc, v) => acc + v.value, 0);
    return aSum - bSum;
  });
};

export { CustomRadarChart as RadarChart };
