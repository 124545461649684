import { twMerge } from "tailwind-merge";

import type { BaseProtocol, Expert, PillarType } from "@acme/db";
import { Badge } from "@acme/ui/badge";

import { NextImage } from "../core/NextImageReact";

type Size = "sm" | "md" | "lg";

interface Props {
  protocol: BaseProtocol & { pillar: PillarType };
  expert: Pick<Expert, "image" | "name">;
  onClick?: () => void;
}

const ProtocolCard = (props: Props & { size: Size }) => {
  const { size, ...rest } = props;

  if (size === "sm") {
    return <ProtocolCardSmall {...rest} />;
  }

  if (size === "md") {
    return <ProtocolCardMedium {...rest} />;
  }

  if (size === "lg") {
    return <ProtocolCardLarge {...rest} />;
  }
};

export const ProtocolCardPlaceholder = (props: { active?: boolean }) => {
  return (
    <div
      data-test="protocol-card"
      className="group flex cursor-pointer items-center gap-3 rounded-lg p-2 "
    >
      <div className="h-12 w-12 animate-fade rounded-lg bg-base-300 bg-center object-cover" />
      <div className="flex flex-col items-start gap-2">
        <div className="h-2 w-24 rounded-xl bg-base-300"></div>
        <div className="flex items-center gap-1.5 ">
          <div
            className={twMerge(
              "h-4 w-4 animate-fade rounded-full bg-base-200 bg-center object-cover transition-all duration-500",
              props.active && "bg-base-300",
            )}
          />
          <div
            className={twMerge(
              "h-1.5 w-12 rounded-xl bg-base-200 transition-all duration-500",
              props.active && "bg-base-300",
            )}
          ></div>
          <div
            className={twMerge(
              "h-1.5 w-20 rounded-xl bg-base-200 transition-all duration-500",
              props.active && "bg-base-300",
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};

const ProtocolCardSmall = (props: Props) => {
  const { protocol, expert, onClick } = props;

  return (
    <div
      data-test="protocol-card"
      className={twMerge(
        " group flex items-center gap-3 rounded-lg p-2 ",
        props.onClick && "cursor-pointer hover:bg-base-200",
      )}
      onClick={onClick}
    >
      <div className="relative">
        <NextImage
          src={`/protocols/${protocol.name}.jpg`}
          alt={`Image of ${protocol.name}`}
          height={128}
          width={128}
          className="h-12 w-12 rounded-lg bg-center object-cover"
        />
      </div>
      <div className="flex grow flex-col gap-0.5">
        <div className="flex flex-grow items-center justify-between gap-2">
          <h3 className="flex-shrink truncate whitespace-nowrap font-semibold">
            {protocol.name}
          </h3>
        </div>
        <div className="flex w-fit items-center gap-1.5">
          <h4 className="text-xs text-base-placeholder">Inspired by</h4>
          <NextImage
            src={expert.image}
            alt={`Image of ${expert.name}`}
            width={32}
            height={32}
            className="h-4 w-4 rounded-full bg-center object-cover"
          />
        </div>
      </div>
    </div>
  );
};

const ProtocolCardMedium = ({ protocol, expert, onClick }: Props) => {
  return (
    <div
      data-test="protocol-card"
      className="group flex cursor-pointer flex-col gap-3"
      onClick={onClick}
    >
      <div className="relative overflow-clip rounded-lg">
        <NextImage
          variant="fade-in"
          src={`/protocols/${protocol.name}.jpg`}
          alt={`Image of ${protocol.name}`}
          height={540}
          width={540}
          className="aspect-square w-full bg-center object-cover transition-all duration-300 group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-50"></div>
        <Badge
          color={protocol.pillar}
          className="absolute bottom-2 left-2 text-base-100"
        >
          {protocol.pillar}
        </Badge>
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-xl font-semibold">{protocol.name}</h3>
        <div className="flex items-center gap-2 overflow-clip">
          <span className="whitespace-nowrap text-sm text-base-placeholder">
            Inspired by
          </span>
          <NextImage
            src={expert.image}
            alt={`Image of ${expert.name}`}
            width={32}
            height={32}
            className="h-4 w-4 rounded-full bg-center object-cover"
          />
          <h4 className="truncate whitespace-nowrap text-sm font-medium">
            {expert.name}
          </h4>
        </div>
      </div>
    </div>
  );
};

const ProtocolCardLarge = ({ protocol, expert, onClick }: Props) => {
  return (
    <div
      data-test="protocol-card"
      className="group flex cursor-pointer flex-col gap-3"
      onClick={onClick}
    >
      <div className="relative overflow-clip rounded-lg">
        <NextImage
          variant="fade-in"
          src={`/protocols/${protocol.name}.jpg`}
          alt={`Image of ${protocol.name}`}
          className="aspect-[544/220] w-full rounded-lg bg-center object-cover transition-all duration-300 group-hover:scale-105"
          height={220}
          width={540}
        />
        <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-50"></div>
        <Badge
          color={protocol.pillar}
          className="absolute bottom-2 left-2 text-base-100"
        >
          {protocol.pillar}
        </Badge>
      </div>
      <div className="flex flex-col items-start gap-0.5">
        <h3 className="text-xl font-semibold">{protocol.name}</h3>
        <div className="flex items-center gap-2">
          <span className="whitespace-nowrap text-sm text-base-placeholder">
            Inspired by
          </span>
          <div className="flex items-center gap-1.5">
            <NextImage
              src={expert.image}
              alt={`Image of ${expert.name}`}
              width={32}
              height={32}
              className="h-5 w-5 rounded-full bg-center object-cover"
            />
            <h4 className="truncate whitespace-nowrap text-sm font-medium">
              {expert.name}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProtocolCard };
