import { AnalyticsBrowser } from "@segment/analytics-next";
import * as snippet from "@segment/snippet";

import type { AnalyticsEvent } from "./types";

export const loadSegmentSnippet = () => {
  const options = { apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY };
  return snippet.min(options);
};

const client = AnalyticsBrowser.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY!,
});

const track = async (event: AnalyticsEvent) => {
  const properties = "properties" in event ? event.properties : undefined;
  const timestamp = "timestamp" in event ? event.timestamp : undefined;

  return client.track(event.event, {
    ...properties,
    timestamp,
  });
};

const analytics = {
  client,
  track,
};

export default analytics;
