import React from "react";
import type {
  IconDefinition,
  IconProp,
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { activityIconNames } from "../../lib/icons";

export type ActivityIconName = (typeof activityIconNames)[number];

interface IconProps
  extends React.ComponentPropsWithoutRef<typeof FontAwesomeIcon> {
  icon: ActivityIconName | IconDefinition;
}

const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ icon, ...props }, ref) => {
    return <FontAwesomeIcon icon={icon as IconProp} ref={ref} {...props} />;
  },
);

Icon.displayName = "Icon";

export { Icon, activityIconNames };
