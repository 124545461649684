import type { AppType } from "next/app";
import { config } from "@fortawesome/fontawesome-svg-core";
import { ClerkProvider, SignedIn } from "@acme/auth";
import { api } from "~/utils/api";
import "~/styles/globals.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Toaster } from "@acme/ui/sonner";
import { CalendarTour } from "~/components/calendar/CalendarTour";
import { SEO } from "~/components/core/SEO";
import { TimezoneChangeTracker } from "~/components/dates/TimezoneChangeTracker";
import { ThemeProvider } from "~/providers/theme";
import { SegmentIdentify } from "~/utils/analytics";
import { initIconLibrary } from "../utils/icons";
config.autoAddCss = false;
initIconLibrary();
const MyApp: AppType = ({
  Component,
  pageProps
}) => {
  return <>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <ClerkProvider>
        <ThemeProvider>
          <SEO />
          <Toaster />
          <CalendarTour />
          <Component {...pageProps} />
          <SegmentIdentify />
          <SignedIn>
            <TimezoneChangeTracker />
          </SignedIn>
        </ThemeProvider>
      </ClerkProvider>
    </>;
};
export default api.withTRPC(MyApp);