import { useEffect } from "react";
import { useRouter } from "next/router";
import { captureException } from "@sentry/nextjs";

import { useAuth, useUser } from "@acme/auth";
import analytics from "@acme/segment/client";

import { api } from "./api";

export const SegmentIdentify = () => {
  const router = useRouter();
  const { userId } = useAuth();
  const { user } = useUser();

  api.user.getVitalUserId.useQuery(undefined, {
    enabled: !!userId,
    onSuccess: (vitalUserId) => {
      if (!user?.id) {
        captureException(
          `Found undefined clerk user id when identifying user ${vitalUserId}`,
        );
        return;
      }
      const analyticsUserId = localStorage.getItem("ajs_user_id");
      const strippedAjsUserId = analyticsUserId
        ? analyticsUserId.replace(/['"]/g, "")
        : null;

      if (!strippedAjsUserId || strippedAjsUserId !== vitalUserId) {
        console.log("identifying:", vitalUserId);

        void analytics.client.identify(vitalUserId, {
          email: user.primaryEmailAddress?.emailAddress,
          name: user?.fullName,
        });
      }
    },
  });

  useEffect(() => {
    void analytics.client.page(router.pathname);
  }, [router.pathname]);

  return null;
};

export default analytics;
