"use client";

import * as React from "react";
import dayjs from "dayjs";

import { cn } from "../../lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ScrollArea } from "./scroll-area";

const TimeSelect = React.forwardRef<
  React.ElementRef<typeof Command>,
  React.ComponentPropsWithoutRef<typeof Command>
>(({ className, children, value, ...props }, ref) => {
  const [isFresh, setIsFresh] = React.useState(true);
  const [inputValue, setInputValue] = React.useState<string>();
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  React.useEffect(() => {
    // set timeout used to ensure popover contents are in DOM before querying
    setTimeout(() => {
      if (popoverOpen && value) {
        const selectedItem = document?.querySelector(`[data-value="${value}"]`);
        if (selectedItem) {
          selectedItem.scrollIntoView({ block: "center", behavior: "instant" });
        }
      }
    });
  }, [popoverOpen, value]);

  return (
    <TimeSelectContext.Provider
      value={{
        value,
        isFresh,
        onValueChange: props.onValueChange,
        onInputValueChange: (val) => setInputValue(val),
        inputValue,
        setIsFresh,
        popoverOpen,
        setPopoverOpen,
      }}
    >
      <Command
        ref={ref}
        defaultValue={"set time"}
        // return all items if "fresh" open of time-select, otherwise filter out items that don't match the input value
        filter={isFresh ? () => 1 : undefined}
        className={cn(
          "relative flex flex-col overflow-hidden rounded bg-popover text-popover-foreground ",
          className,
        )}
        {...props}
      >
        <Popover
          open={popoverOpen}
          onOpenChange={(val) => {
            if (val) setIsFresh(true); // ensure no filtering occurs on first open
            setPopoverOpen(val);
            if (!val) setInputValue(value);
          }}
        >
          {children}
        </Popover>
      </Command>
    </TimeSelectContext.Provider>
  );
});
TimeSelect.displayName = Command.displayName;

const TimeSelectInput = React.forwardRef<
  React.ElementRef<typeof CommandInput>,
  React.ComponentPropsWithoutRef<typeof CommandInput>
>(({ className, ...props }, ref) => {
  const { setIsFresh, onInputValueChange, inputValue, value, setPopoverOpen } =
    useTimeSelectContext();
  return (
    <PopoverTrigger
      asChild
      onClick={() => {
        setTimeout(() => setPopoverOpen(true));
      }}
    >
      <CommandInput
        ref={ref}
        placeholder="set time"
        onFocus={(e) => {
          setTimeout(() => {
            e.target.selectionStart = e.target.selectionEnd = 10000; // sets cursor to end of input
          }, 0);
        }}
        value={inputValue ?? value}
        onValueChange={(val) => {
          setIsFresh(false);
          onInputValueChange?.(val);
          setPopoverOpen(true);
        }}
        className={cn(
          "w-full border-base-300 placeholder:text-base-placeholder focus:bg-base-200",
          className,
        )}
        {...props}
      />
    </PopoverTrigger>
  );
});
TimeSelectInput.displayName = CommandInput.displayName;

const TimeSelectGroup = React.forwardRef<
  React.ElementRef<typeof PopoverContent>,
  React.ComponentPropsWithoutRef<typeof PopoverContent>
>(({ className, children, ...props }, ref) => {
  return (
    <PopoverContent
      ref={ref}
      align="end"
      onOpenAutoFocus={(e) => e.preventDefault()}
      className={cn("p-0", className)}
      {...props}
    >
      <ScrollArea className="max-h-48">
        <CommandEmpty>Time not found.</CommandEmpty>
        <CommandGroup className="">{children}</CommandGroup>
      </ScrollArea>
    </PopoverContent>
  );
});

const TimeSelectItem = React.forwardRef<
  React.ElementRef<typeof CommandItem>,
  React.ComponentPropsWithoutRef<typeof CommandItem>
>(({ className, children, ...props }, ref) => {
  const { onValueChange, setPopoverOpen, value, onInputValueChange } =
    useTimeSelectContext();
  const isSelected = value === props.value;

  return (
    <CommandItem
      ref={ref}
      data-value={props.value}
      className={cn(
        "rounded px-2 text-base transition-colors duration-200 hover:bg-muted",
        isSelected && "bg-muted",
        className,
      )}
      onSelect={(currentValue) => {
        onInputValueChange?.(currentValue);
        onValueChange?.(currentValue);
        setPopoverOpen(false);
      }}
      {...props}
    >
      {children}
      {/* {isSelected && <Icon className="ml-auto" icon={faCheck} />} */}
    </CommandItem>
  );
});
TimeSelectItem.displayName = CommandItem.displayName;

/**
 * Generates an array of times within a day, based on a specified interval.
 *
 * @param interval - An object containing 'value' as the number of units and 'units' as the type of time unit (e.g., 'minute', 'hour').
 * @returns An array of objects, each containing a 'value' and 'label' representing the formatted time.
 */
function generateDayTimes(interval: {
  value: number;
  units: "minutes" | "hours";
}): { value: string; label: string }[] {
  // Validate the interval input
  if (interval.value <= 0) {
    throw new Error("Interval value must be greater than zero.");
  }

  // Initialize an array to hold the time values
  const times: { value: string; label: string }[] = [];

  // Start at the beginning of the current day
  let currentTime = dayjs().startOf("day");

  // Loop until the end of the day, adding time intervals
  while (currentTime.isBefore(dayjs().endOf("day"))) {
    const formattedTime = currentTime.format("h:mm a"); // Format time as a human-readable string
    times.push({ value: formattedTime, label: formattedTime });
    currentTime = currentTime.add(interval.value, interval.units);
  }

  return times;
}

interface TimeSelectContextType {
  value?: string;
  onValueChange?: (value: string) => void;
  inputValue?: string;
  onInputValueChange?: (value: string) => void;
  isFresh: boolean;
  setIsFresh: React.Dispatch<React.SetStateAction<boolean>>;
  popoverOpen: boolean;
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValue: TimeSelectContextType = {
  isFresh: false,
  setIsFresh: (v) => v,
  popoverOpen: false,
  setPopoverOpen: (v) => v,
};

const TimeSelectContext =
  React.createContext<TimeSelectContextType>(defaultValue);

const useTimeSelectContext = () => React.useContext(TimeSelectContext);

export {
  TimeSelect,
  TimeSelectGroup,
  TimeSelectInput,
  TimeSelectItem,
  generateDayTimes,
};
