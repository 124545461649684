import { useEffect, useState } from "react";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { twMerge } from "tailwind-merge";

import type { ActivitySchedule, DayOfWeekType } from "@acme/db";
import analytics from "@acme/segment/client";
import { Badge } from "@acme/ui/badge";
import { Days } from "@acme/validation";

import { ActivityDetailsModal } from "~/components/calendar/ActivityDetails";
import { useProtocolStore } from "~/store/calendar";
import { getPillarColor } from "~/utils/colors";
import { createDayjs, getDateFromDayOfWeekType } from "~/utils/time";
import { ActivityIcon } from "../ActivityIcon";
import { EditScheduleDetails } from "./EditScheduleDetails";

const ProtocolActivitiesScheduler = () => {
  const {
    resetFocusedSchedules,
    stagedActivitySchedules,
    focusedProtocolId,
    generateInitialSchedules,
    getFocusedSchedules,
    getFocusedUserProtocol,
  } = useProtocolStore();

  const [canReset, setCanReset] = useState(false);

  useEffect(() => {
    const focusedSchedules = getFocusedSchedules();
    setCanReset(focusedSchedules.filter((s) => s.startTime).length > 0);
  }, [focusedProtocolId, getFocusedSchedules, stagedActivitySchedules]);

  useEffect(() => {
    const focusedSchedules = getFocusedSchedules();
    const focusedUserProtocol = getFocusedUserProtocol();
    setCanReset(focusedSchedules.filter((s) => s.startTime).length > 0);
    if (focusedSchedules.length === 0 && focusedProtocolId) {
      generateInitialSchedules({
        protocolId: focusedProtocolId,
        userProtocolId: focusedUserProtocol?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedProtocolId]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <h4 className="font-semibold">Activities</h4>
        {canReset && (
          <Badge
            data-test="reset-activities-button"
            variant={"outline"}
            color="nutrition"
            onClick={resetFocusedSchedules}
          >
            reset
          </Badge>
        )}
      </div>
      <div className="flex flex-col gap-2">
        {Days.map((day) => {
          return (
            <div className="flex items-start md:gap-3" key={day}>
              <div className="flex h-14 w-12 items-center text-sm font-medium text-base-placeholder">
                {day.slice(0, 3).toUpperCase()}
              </div>
              <div className="flex grow flex-col gap-2">
                <ScheduleSlots day={day} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ScheduleSlots = ({ day }: { day: DayOfWeekType }) => {
  const [sortedSchedules, setSortedSchedules] = useState<ActivitySchedule[]>(
    [],
  );
  const {
    activities,
    focusedProtocolId,
    activitySchedules,
    stagedActivitySchedules,
    getFocusedSchedules,
  } = useProtocolStore();

  useEffect(() => {
    const schedules = getFocusedSchedules().filter((s) => s.dayOfWeek === day);
    setSortedSchedules(schedules);
  }, [
    day,
    focusedProtocolId,
    activities,
    activitySchedules,
    stagedActivitySchedules,
    getFocusedSchedules,
  ]);

  if (!sortedSchedules.length) {
    return (
      <div className="flex h-14 grow items-center rounded-2xl border"></div>
    );
  }

  return (
    <>
      {sortedSchedules.map((schedule, index) => {
        return (
          <ScheduleItem
            day={day}
            key={schedule.id}
            scheduleId={schedule.id}
            activityId={schedule.activityId}
            scheduleItemIndex={index}
          />
        );
      })}
    </>
  );
};

const ScheduleItem = (props: {
  activityId: string;
  scheduleId: string;
  day: DayOfWeekType;
  scheduleItemIndex?: number;
}) => {
  const isFirstItem = props.scheduleItemIndex === 0;
  const { activities, getFocusedSchedules } = useProtocolStore();
  const activity = activities[props.activityId];

  const schedule = getFocusedSchedules().find(
    (schedule) => schedule.id === props.scheduleId,
  );

  if (!activity || !schedule) {
    return null;
  }

  const pillarColor = getPillarColor(activity.pillar);
  const day = getDateFromDayOfWeekType(props.day);
  const date = schedule.startTime
    ? createDayjs(schedule.startTime, day).toDate()
    : null;

  return (
    <ActivityDetailsModal
      isPreview={true}
      event={{
        activityId: schedule.activityId,
        scheduledFor: date,
        completedAt: null,
      }}
    >
      <div
        data-test="schedule-item"
        data-tour-step={isFirstItem ? "schedule-item" : undefined}
        className="flex h-14 grow items-center rounded-2xl border"
      >
        <div
          onClick={() => {
            void analytics.track({
              event: "activity_viewed",
              properties: {
                id: activity.id,
                name: activity.name,
                source: "activity_scheduler",
              },
            });
          }}
          className={twMerge(
            "flex h-full grow items-center justify-between gap-3 rounded-2xl px-3 pl-4",
            "cursor-pointer hover:bg-base-200/50",
          )}
        >
          <div className={`flex flex-row items-center gap-4 ${pillarColor}`}>
            <ActivityIcon
              icon={activity.icon as IconProp}
              className={`w- h-5`}
            />
            <span className="w-full max-w-[110px] truncate font-semibold min-[414px]:max-w-[165px] md:max-w-[260px]">
              {activity.name}
            </span>
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <EditScheduleDetails
              scheduleId={props.scheduleId}
              activityId={props.activityId}
              day={props.day}
            />
          </div>
        </div>
      </div>
    </ActivityDetailsModal>
  );
};

export default ProtocolActivitiesScheduler;
