import { useState } from "react";
import { twMerge } from "tailwind-merge";

import { cn } from "~/lib/utils";
import type { NextImageProps } from "./NextImageCore";
import { imageVariants, NextImage as NextImageCore } from "./NextImageCore";

export const NextImage = ({
  variant,
  className,
  src,
  ...props
}: NextImageProps) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <NextImageCore
      {...props}
      src={hasError ? "not-found.svg" : src}
      alt={props.alt}
      onLoad={() => setHasLoaded(true)}
      onError={(_err) => setHasError(true)}
      className={cn(
        imageVariants({
          variant,
          hasLoaded: variant === "fade-in" ? hasLoaded : true,
          className: twMerge(
            hasError && `rounded-lg object-scale-down p-4`,
            className,
          ),
        }),
      )}
    />
  );
};
