import * as React from "react";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Slot } from "@radix-ui/react-slot";

import { cn } from "../../lib/utils";
import type { ActivityIconName } from "./icon";
import { Ping } from "./ping";

/**
 * Contains all options for button variants and sizes. Named "classname" to get
 * tailwind intellisense support
 */
const className = {
  color: {
    default: {
      default: "bg-foreground text-background",
      subtle: "bg-muted text-foreground",
      outline: "text-foreground hover:bg-muted border-foreground",
      ghost: "text-foreground",
      link: "text-foreground",
    },
    brand: {
      default: "bg-primary text-primary-foreground",
      subtle: "bg-primary-subtle text-primary",
      outline:
        "text-primary border-primary hover:bg-primary/[0.06] dark:hover:bg-primary/[0.12]",
      ghost: "text-primary",
      link: "text-primary",
    },
    destructive: {
      default: "bg-destructive destructive-foreground",
      subtle:
        "bg-destructive/[0.12] dark:bg-destructive/[0.18] text-destructive hover:bg-destructive/[0.06] dark:hover:bg-destructive/[0.09]",
      outline:
        "text-destructive border-destructive hover:bg-destructive/[0.06] dark:hover:bg-destructive/[0.12]",
      ghost: "text-destructive",
      link: "text-destructive",
    },
    recovery: {
      default: "bg-recovery text-recovery-foreground",
      subtle: "bg-recovery-subtle text-recovery",
      outline:
        "text-recovery border-recovery hover:bg-recovery/[0.06] dark:hover:bg-recovery/[0.12]",
      ghost: "text-recovery",
      link: "text-recovery",
    },
    fitness: {
      default: "bg-fitness text-fitness-foreground",
      subtle: "bg-fitness-subtle text-fitness",
      outline:
        "text-fitness border-fitness hover:bg-fitness/[0.06] dark:hover:bg-fitness/[0.12]",
      ghost: "text-fitness",
      link: "text-fitness",
    },
    nutrition: {
      default: "bg-nutrition text-nutrition-foreground",
      subtle: "bg-nutrition-subtle text-nutrition",
      outline:
        "text-nutrition border-nutrition hover:bg-nutrition/[0.06] dark:hover:bg-nutrition/[0.12]",
      ghost: "text-nutrition",
      link: "text-nutrition",
    },
    mindfulness: {
      default: "bg-mindfulness text-mindfulness-foreground",
      subtle: "bg-mindfulness-subtle text-mindfulness",
      outline:
        "text-mindfulness border-mindfulness hover:bg-mindfulness/[0.06] dark:hover:bg-mindfulness/[0.12]",
      ghost: "text-mindfulness",
      link: "text-mindfulness",
    },
  },
  size: {
    unstyled: "",
    default: "h-11 px-4",
    fun: "h-14 px-8 rounded-2xl w-full md:w-64",
    icon: "h-11 w-11 rounded-lg",
    "icon-sm": "h-5 text-xs w-5 rounded",
    "icon-fun": "h-12 w-12 rounded-2xl",
  },
  variant: {
    default: "hover:shadow-lg dark:shadow-foreground/20", // aka solid
    subtle: "hover:opacity-70",
    outline: "border",
    ghost: "hover:opacity-50",
    link: "underline-offset-4 hover:underline",
  },
};

/* for use in button.stories.tsx */
const colors = Object.keys(className.color) as (keyof typeof className.color)[];
const sizes = Object.keys(className.size) as (keyof typeof className.size)[];
const variants = Object.keys(
  className.variant,
) as (keyof typeof className.variant)[];

export { Button, colors, sizes, variants };

/* button component */
type Color = keyof typeof className.color;
type Size = keyof typeof className.size;
type Variant = keyof typeof className.variant;

interface ButtonVarsProps {
  color: Color;
  size: Size;
  variant: Variant;
}

export const buttonVariants = ({
  variant: selectedVariant,
  color: selectedColor,
  size: selectedSize,
}: ButtonVarsProps) => {
  return cn(
    "flex gap-2 relative inline-flex items-center justify-center whitespace-nowrap",
    "rounded-md text-base font-medium",
    "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
    "disabled:pointer-events-none disabled:opacity-50",
    "transition-all duration-200",
    className.color[selectedColor][selectedVariant],
    className.variant[selectedVariant],
    className.size[selectedSize],
  );
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  leftIcon?: IconDefinition | ActivityIconName;
  rightIcon?: IconDefinition | ActivityIconName;
  withPing?: boolean;
  isLoading?: boolean;
  asChild?: boolean;
  color?: Color;
  variant?: Variant;
  size?: Size;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      variant,
      size,
      color,
      asChild = false,
      withPing,
      leftIcon,
      rightIcon,
      isLoading,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({
            color: color ?? "default",
            size: size ?? "default",
            variant: variant ?? "default",
          }),
          className,
        )}
        ref={ref}
        {...props}
      >
        {withPing && <Ping />}
        {leftIcon && (
          <FontAwesomeIcon
            className={cn(isLoading && "animate-spin duration-700")}
            icon={isLoading ? faSpinnerThird : leftIcon}
          />
        )}
        {children}
        {rightIcon && (
          <FontAwesomeIcon
            className={cn(isLoading && "animate-spin duration-700")}
            icon={isLoading ? faSpinnerThird : rightIcon}
          />
        )}
      </Comp>
    );
  },
);
Button.displayName = "Button";
