import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import type { TooltipContentProps } from "@radix-ui/react-tooltip";
import { Portal, TooltipArrow } from "@radix-ui/react-tooltip";
import { createPortal } from "react-dom";
import { create } from "zustand";

import { useUser } from "@acme/auth";
import type { UserResource } from "@acme/auth/types";
import { Icon } from "@acme/ui/icon";
import { Separator } from "@acme/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@acme/ui/tooltip";

import { useProtocolStore, useSideDrawerStore } from "~/store/calendar";
import { useBreakpoint } from "~/utils/breakpoints";
import { ANIMATION_DURATION } from "./CalendarSideDrawer";

const targets = [
  "browse-protocols-button",
  "protocol-catalog",
  "customize-protocol-button",
  "protocol-variant-button",
  "schedule-item",
  "save-user-protocol-button",
  "calendar-block",
] as const;

type TourStepTarget = (typeof targets)[number];
interface TourStep {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  tooltipContentProps?: TooltipContentProps & {
    hideArrow?: boolean;
  };
  mobileTooltipPosition?: {
    top?: number;
    bottom?: number;
  };
  shouldScrollIntoView?: boolean;
  target: TourStepTarget;
  // tooltipTop?: number; // could be used to override the tooltip position
}

interface CalendarTourState {
  currentStep: TourStepTarget | null;
  enabled: boolean;
  isDone: boolean;
  // completedSteps is used to determine which step to show next
  completedSteps: TourStepTarget[];
}

export const useCalendarTourStore = create<CalendarTourState>(() => ({
  currentStep: "browse-protocols-button",
  enabled: false,
  isDone: false,
  completedSteps: [],
}));

export const CalendarTour = () => {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const isDesktop = useBreakpoint("md");
  const router = useRouter();

  const { enabled, isDone, currentStep } = useCalendarTourStore();
  const step = tourSteps[currentStep!];
  const { user } = useUser();

  useEffect(() => {
    if (user && router.asPath !== "/welcome" && !isDone) {
      enableTourIfNecessary(user);
    }
  }, [isDone, user, router.asPath]);

  useEffect(() => {
    if (enabled && isDesktop) {
      // add overflow hidden to html and body to prevent weird html/body scroll behavior
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    }
    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    };
  }, [enabled, isDesktop]);

  // scroll activity block into view on mobile
  useEffect(() => {
    if (!isDesktop && currentStep === "calendar-block") {
      const element = document.querySelector(`calendar-block`);
      if (!element) return;
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isDesktop, currentStep]);

  useEffect(() => {
    if (!enabled || !step) return;

    // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
    const element = document.querySelector(
      `[data-tour-step="${step.target}"]`,
    ) as HTMLElement;
    if (!element) return;

    const tooltipDiv = prepareTooltipDiv(element, step);
    setPortalElement(tooltipDiv);
  }, [step, enabled]);

  if (!isDesktop) {
    return <MobileTourTooltip />;
  }

  return portalElement ? createPortal(<TourTooltip />, portalElement) : null;
};

export const useShowCalendarTourStep = (step: TourStepTarget) => {
  const { mode } = useSideDrawerStore();
  const router = useRouter();

  const { activityEvents } = useProtocolStore();

  useEffect(() => {
    setCalendarTourStep(step);

    if (
      router.asPath === "/protocols/[id]" &&
      mode === "protocol-customization"
    ) {
      setCalendarTourStep("protocol-variant-button");
    }

    return () => {
      useCalendarTourStore.setState({ currentStep: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (
      step === "browse-protocols-button" &&
      Object.values(activityEvents).length > 0
    ) {
      setCalendarTourStep("calendar-block");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityEvents]);
};

const enableTourIfNecessary = (user: UserResource) => {
  const finishedCalendarTour = user?.publicMetadata?.finishedCalendarTour;
  if (!finishedCalendarTour) {
    useCalendarTourStore.setState({ enabled: true });
  }
};

const prepareTooltipDiv = (
  element: HTMLElement,
  step: TourStep,
): HTMLElement => {
  element.style.position = "relative";
  const tooltipDiv = document.createElement("div");
  tooltipDiv.id = "tooltip-trigger";
  Object.assign(tooltipDiv.style, {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: `${element.clientWidth}px`,
    height: `${element.clientHeight}px`,
    pointerEvents: "none",
  });

  element.appendChild(tooltipDiv);

  if (step?.shouldScrollIntoView) {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  return tooltipDiv;
};

const MobileTourTooltip = () => {
  const { enabled, currentStep } = useCalendarTourStore();
  const step = tourSteps[currentStep!];
  const { top = undefined, bottom = undefined } =
    step?.mobileTooltipPosition ?? {};

  if (!step || !enabled) return null;

  return (
    <div className="fixed z-50 w-screen p-4" style={{ top, bottom }}>
      <div className="animate-fade overflow-visible rounded-xl border-transparent bg-base-content/70 p-3 text-base-100 backdrop-blur-sm">
        <div className="flex flex-col items-stretch gap-1 md:max-w-xs">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">{step?.title}</h3>
            <Icon
              icon={faTimes}
              className="cursor-pointer hover:opacity-50"
              onClick={() => {
                useCalendarTourStore.setState({ currentStep: null });
              }}
            />
          </div>
          <h5 className="whitespace-normal text-left">{step?.description}</h5>
          <div className="mt-2 flex gap-2">
            {Object.keys(tourSteps).map((target) => (
              <div
                key={target}
                className={`h-3 w-3 rounded ${
                  currentStep === target ? "bg-base-100" : "bg-base-100/50"
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TourTooltip = () => {
  const { enabled, currentStep } = useCalendarTourStore();
  const step = tourSteps[currentStep!];

  if (!step || !enabled) return null;

  return (
    <TooltipProvider>
      <Tooltip defaultOpen open>
        <TooltipTrigger></TooltipTrigger>
        <Portal>
          <TooltipContent
            data-test={`${step.target}-tooltip`}
            {...step?.tooltipContentProps}
            collisionPadding={24}
            id="tooltip-content"
            className="animate-fade overflow-visible rounded-xl border-transparent bg-base-content/70 p-3 text-base-100 backdrop-blur-sm"
          >
            <div className="flex max-w-xs flex-col items-stretch gap-1">
              <div className="flex justify-between">
                <h3 className="font-semibold">{step?.title}</h3>
                <Icon
                  icon={faTimes}
                  className="cursor-pointer hover:opacity-50"
                  onClick={() => {
                    useCalendarTourStore.setState({ currentStep: null });
                  }}
                />
              </div>
              <h5 className="whitespace-normal text-left">
                {step?.description}
              </h5>
              <div className="mt-2 flex gap-2">
                {Object.keys(tourSteps).map((target) => (
                  <div
                    key={target}
                    className={`h-3 w-3 rounded ${
                      currentStep === target ? "bg-base-100" : "bg-base-100/50"
                    }`}
                  />
                ))}
              </div>
            </div>
            {!step.tooltipContentProps?.hideArrow && (
              <TooltipArrow asChild>
                <div className="bottm-1 relative h-4 w-1 rounded-b-sm bg-base-content/70"></div>
              </TooltipArrow>
            )}
          </TooltipContent>
        </Portal>
      </Tooltip>
    </TooltipProvider>
  );
};

export const setCalendarTourStep = (step: TourStepTarget) => {
  const { currentStep, completedSteps } = useCalendarTourStore.getState();

  // hide the existing tooltip
  useCalendarTourStore.setState({ currentStep: null });

  // show the new tooltip after a delay, to allow animations to complete... otherwise positioning is off
  setTimeout(() => {
    useCalendarTourStore.setState({
      currentStep: step,
      completedSteps: [...completedSteps, currentStep!].filter(Boolean),
    });
  }, ANIMATION_DURATION);
};

export const tourSteps: Record<TourStepTarget, TourStep> = {
  "browse-protocols-button": {
    target: "browse-protocols-button",
    title: "Protocols",
    description: `Click "+" to add new protocols to your holistic routine`,
    tooltipContentProps: {
      side: "left",
      sideOffset: 55,
    },
    mobileTooltipPosition: {
      top: 80,
    },
  },
  "protocol-catalog": {
    target: "protocol-catalog",
    title: "Choose a protocol",
    description: `Select a protocol you want to implement`,
    tooltipContentProps: {
      side: "right",
      sideOffset: 200,
      hideArrow: true,
    },
    mobileTooltipPosition: {
      bottom: 72,
    },
  },
  "customize-protocol-button": {
    target: "customize-protocol-button",
    title: (
      <>
        <span className="md:hidden">Protocol details</span>
        <span className="max-md:hidden">{"View protocol details"}</span>
      </>
    ),
    description: (
      <>
        <div className="flex flex-col items-stretch gap-2 max-md:hidden">
          <span className="">
            Browse the details, resources and benefits of this protocol.
          </span>
          <Separator className="opacity-50" />
          <span>When ready, click below to start scheduling activities.</span>
        </div>
        <div className=" md:hidden">
          <span className="">
            Browse the details of this protocol, then click{" "}
            {"Customize Protocol"} to start scheduling activities.
          </span>
        </div>
      </>
    ),
    tooltipContentProps: {
      side: "top",
      sideOffset: 20,
    },
    mobileTooltipPosition: {
      top: 56,
    },
  },
  "protocol-variant-button": {
    target: "protocol-variant-button",
    title: "Select an option",
    description: `Start customizing this protocol by choosing a difficulty that fits your needs`,
    tooltipContentProps: {
      side: "left",
      sideOffset: 300,
    },
    mobileTooltipPosition: {
      bottom: 64,
    },
  },
  "schedule-item": {
    target: "schedule-item",
    title: "Set start times",
    description: `Click to preview any activity and press "set time" to adjust your schedule. Unscheduled activities of the same type will auto-set to the same time.`,
    tooltipContentProps: {
      side: "left",
      sideOffset: 10,
    },
  },
  "save-user-protocol-button": {
    target: "save-user-protocol-button",
    title: "Save your protocol",
    description: `When finished scheduling all activities, click to save and add to calendar`,
    shouldScrollIntoView: true,
    tooltipContentProps: {
      side: "left",
      sideOffset: 200,
    },
  },
  "calendar-block": {
    target: "calendar-block",
    title: "View activity",
    description: `Select an activity to see the step by step instructions`,
    shouldScrollIntoView: true,
    tooltipContentProps: {
      side: "top",
      sideOffset: 25,
    },
    mobileTooltipPosition: {
      bottom: 64,
    },
  },
};
