import { useEffect, useState } from "react";

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

export const useBreakpoint = (breakpoint: Breakpoint = "md"): boolean => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      `(min-width: ${breakpoints[breakpoint]}px)`,
    );
    setMatches(mediaQuery.matches);

    const handler = () => setMatches(mediaQuery.matches);

    mediaQuery.addEventListener("change", handler);

    return () => mediaQuery.removeEventListener("change", handler);
  }, [breakpoint]);

  return matches;
};

const breakpoints: Record<Breakpoint, number> = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export const useHasWindowScrolled = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const isDesktop = useBreakpoint("md");

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = isDesktop
        ? (document.getElementById("app-scroll-area")?.scrollTop ?? 0) > 16
        : window.scrollY > 16;
      setHasScrolled(isScrolled);
    };

    if (isDesktop) {
      const scrollArea = document.getElementById("app-scroll-area");
      scrollArea?.addEventListener("scroll", handleScroll);
      return () => scrollArea?.removeEventListener("scroll", handleScroll);
    } else {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isDesktop]);

  return hasScrolled;
};
