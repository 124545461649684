import { useEffect, useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { captureException } from "@sentry/nextjs";
import dayjs from "dayjs";

import type { DayOfWeekType } from "@acme/db";
import { Button } from "@acme/ui/button";
import {
  generateDayTimes,
  TimeSelect,
  TimeSelectGroup,
  TimeSelectInput,
  TimeSelectItem,
} from "@acme/ui/time-select";
import { Days } from "@acme/validation";

import { useProtocolStore } from "~/store/calendar";
import { setCalendarTourStep } from "../../calendar/CalendarTour";

interface EditScheduleDetailsProps {
  scheduleId: string;
  activityId: string;
  day: DayOfWeekType;
}
export const EditScheduleDetails = (props: EditScheduleDetailsProps) => {
  const { activitySchedules, upsertSchedule, stagedActivitySchedules } =
    useProtocolStore();
  const schedule =
    stagedActivitySchedules[props.scheduleId] ??
    activitySchedules[props.scheduleId];

  useEffect(() => {
    if (!schedule?.startTime) return;
    const element = document.getElementById(`calendar-block-${schedule.id}`);

    setTimeout(() => {
      element?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 400);
  }, [schedule?.id, schedule?.startTime]);

  if (!schedule) {
    return null; // we temporarily get into this state when resetting schedules
  }

  const handleTimeChange = (val: string) => {
    const today = dayjs().format("YYYY/MM/DD");
    const dateTime = dayjs(`${today} ${val}`, "YYYY/MM/DD hh:mm a");
    const formattedTime = dayjs(dateTime).format("HH:mm");

    setCalendarTourStep("save-user-protocol-button");

    upsertSchedule(
      { ...schedule, dayOfWeek: props.day, startTime: formattedTime },
      { autosetSimilarSchedules: true },
    );
  };

  return (
    <div className="flex flex-row items-center gap-2">
      <TimeSelect
        className="w-24"
        value={convertHHmmTohmma(schedule.startTime)}
        onValueChange={handleTimeChange}
      >
        <TimeSelectInput className="h-10 text-base" />
        <TimeSelectGroup className="max-w-[150px]">
          {generateDayTimes({ units: "minutes", value: 15 }).map((time) => (
            <TimeSelectItem key={time.value} value={time.value}>
              {time.label}
            </TimeSelectItem>
          ))}
        </TimeSelectGroup>
      </TimeSelect>
      <DayEditor
        day={props.day}
        activityId={props.activityId}
        scheduleId={props.scheduleId}
      />
    </div>
  );
};

interface DayEditorProps {
  day: DayOfWeekType;
  activityId: string;
  scheduleId: string;
}
const DayEditor = (props: DayEditorProps) => {
  const { canDecrement, decrementDay, canIncrement, incrementDay } =
    useEditDayOfWeek(props);

  return (
    <div className="flex flex-col">
      <Button
        variant="ghost"
        leftIcon={faChevronUp}
        onClick={decrementDay}
        disabled={!canDecrement}
        data-test="decrement-day-button"
        className="h-5 w-5"
      />
      <Button
        variant="ghost"
        leftIcon={faChevronDown}
        onClick={incrementDay}
        disabled={!canIncrement}
        data-test="increment-day-button"
        className="h-5 w-5"
      />
    </div>
  );
};

const useEditDayOfWeek = (args: DayEditorProps) => {
  const {
    focusedProtocolId,
    activities,
    stagedActivitySchedules,
    activitySchedules,
    getFocusedSchedules,
    getFocusedActivities,
  } = useProtocolStore();
  const activity = activities[args.activityId];
  const [canIncrement, setCanIncrement] = useState(false);
  const [canDecrement, setCanDecrement] = useState(false);

  useEffect(() => {
    if (!activity) {
      throw new Error("Activity not found");
    }

    const protocolActivities = getFocusedActivities();

    const scheds = getFocusedSchedules().filter((sched) =>
      protocolActivities.some((a) => a.id === sched.activityId),
    );

    const currentDayIndex = Days.indexOf(args.day);

    // Use modulo to find the next and previous day indices
    const nextDayIndex = (currentDayIndex + 1) % Days.length;
    const prevDayIndex = (currentDayIndex - 1 + Days.length) % Days.length;

    // Extract the names for next and previous days
    const nextDay = Days[nextDayIndex];
    const prevDay = Days[prevDayIndex];

    const nextDaySchedule = scheds.find((s) => s?.dayOfWeek === nextDay);
    const prevDaySchedule = scheds.find((s) => s?.dayOfWeek === prevDay);

    // Update state variables
    setCanIncrement(!nextDaySchedule);
    setCanDecrement(!prevDaySchedule);
  }, [
    activities,
    activity,
    activitySchedules,
    args.day,
    focusedProtocolId,
    getFocusedActivities,
    getFocusedSchedules,
    stagedActivitySchedules,
  ]);

  return {
    canIncrement: canIncrement,
    canDecrement: canDecrement,
    incrementDay: () => {
      useProtocolStore.setState((s) => {
        if (!activity) {
          captureException("Activity not found");
          return s;
        }
        const dayIndex = Days.indexOf(args.day);
        const nextDay = Days[(dayIndex + 1) % Days.length];

        if (!nextDay) {
          captureException("Next day not found");
          return s;
        }

        const updatedStagedSchedules = { ...s.stagedActivitySchedules };
        if (args.scheduleId) {
          const stagedSchedToUpdate =
            s.stagedActivitySchedules[args.scheduleId];
          const persistedSchedToUpdate = s.activitySchedules[args.scheduleId];
          if (stagedSchedToUpdate) {
            stagedSchedToUpdate.dayOfWeek = nextDay;
          } else if (persistedSchedToUpdate) {
            updatedStagedSchedules[args.scheduleId] = {
              ...persistedSchedToUpdate,
              dayOfWeek: nextDay,
            };
          }
        }

        return { ...s, stagedActivitySchedules: updatedStagedSchedules };
      });
    },

    decrementDay: () => {
      useProtocolStore.setState((s) => {
        if (!activity) {
          captureException("Activity not found");
          return s;
        }
        const dayIndex = Days.indexOf(args.day);
        const prevDay = Days[(dayIndex - 1 + Days.length) % Days.length];

        if (!prevDay) {
          captureException("Previous day not found");
          return s;
        }

        const updatedStagedSchedules = { ...s.stagedActivitySchedules };
        if (args.scheduleId) {
          const stagedSchedToUpdate =
            s.stagedActivitySchedules[args.scheduleId];
          const persistedSchedToUpdate = s.activitySchedules[args.scheduleId];
          if (stagedSchedToUpdate) {
            stagedSchedToUpdate.dayOfWeek = prevDay;
          } else if (persistedSchedToUpdate) {
            updatedStagedSchedules[args.scheduleId] = {
              ...persistedSchedToUpdate,
              dayOfWeek: prevDay,
            };
          }
        }

        return { ...s, stagedActivitySchedules: updatedStagedSchedules };
      });
    },
  };
};

const convertHHmmTohmma = (HHmm: string) => {
  if (!HHmm) return "";
  const today = dayjs().format("YYYY-MM-DD");
  const dateTime = dayjs(`${today} ${HHmm}`, "YYYY-MM-DD HH:mm");
  const formattedTime = dayjs(dateTime).format("h:mm a");

  return formattedTime;
};
