import { useState } from "react";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";

import { Button } from "@acme/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@acme/ui/dialog";
import { toast } from "@acme/ui/sonner";

import { useProtocolStore, useSideDrawerStore } from "~/store/calendar";
import { api } from "~/utils/api";

export const RemoveUserProtocolButton = (props: { baseProtocolId: string }) => {
  const trpc = api.useContext();
  const [showDialog, setShowDialog] = useState(false);
  const { setMode } = useSideDrawerStore();
  const { baseProtocols, getFocusedUserProtocol } = useProtocolStore();

  const archiveUserProtocol = api.userProtocol.archiveUserProtocol.useMutation({
    onSuccess: async () => {
      toast.success("Successfully removed protocol.", {
        position: "bottom-center",
      });
      void trpc.activityEvent.findActivityEvents.invalidate();
      await trpc.userProtocol.getMyProtocols.invalidate();
      setShowDialog(false);
      setMode("my-protocols", { force: true });
    },
  });

  const handleDelete = () => {
    const userProtocol = getFocusedUserProtocol();
    if (!userProtocol) {
      return;
    }
    archiveUserProtocol.mutate({ userProtocolId: userProtocol.id });
  };

  return (
    <>
      <Button
        data-test="remove-user-protocol-button"
        leftIcon={faTrashCan}
        variant={"subtle"}
        color="destructive"
        onClick={() => setShowDialog(true)}
      >
        Remove
      </Button>
      <Dialog onOpenChange={() => setShowDialog(false)} open={showDialog}>
        <DialogContent
          data-test="confirmation-dialog"
          className="max-h-[100svh] overflow-y-auto overflow-x-hidden bg-base-100 sm:max-w-[425px]"
        >
          <DialogHeader>
            <DialogTitle className="text-xl">Confirm removal</DialogTitle>
          </DialogHeader>
          <DialogDescription className="text-md">
            <>
              You are about to remove{" "}
              <strong>
                {baseProtocols[props.baseProtocolId]?.name ?? "this protocol"}
              </strong>{" "}
              from your routine. Are you sure you want to continue? This action
              cannot be undone.
            </>
          </DialogDescription>
          <DialogFooter className="mt-4 flex flex-row justify-end gap-2">
            <Button
              data-test="confirmation-dialog-cancel-button"
              variant={"outline"}
              onClick={() => setShowDialog(false)}
            >
              Cancel
            </Button>
            <Button
              data-test="confirmation-dialog-confirm-button"
              leftIcon={faTrashCan}
              color="destructive"
              onClick={handleDelete}
            >
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
