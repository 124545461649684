import { useEffect } from "react";
import {
  faCircle,
  faGaugeSimple,
  faGaugeSimpleHigh,
  faGaugeSimpleLow,
  faGaugeSimpleMax,
  faGaugeSimpleMin,
} from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";

import type { Protocol } from "@acme/db";

import { useProtocolStore } from "~/store/calendar";
import { capitalizeFirstChar } from "~/utils/string";
import {
  setCalendarTourStep,
  useCalendarTourStore,
} from "../../calendar/CalendarTour";

interface Props {
  variants: Protocol[];
}

const ProtocolVariantSelector = ({ variants }: Props) => {
  const { focusedProtocolId } = useProtocolStore();
  const { currentStep } = useCalendarTourStore();

  useEffect(() => {
    if (!focusedProtocolId && variants[0]) {
      useProtocolStore.setState({ focusedProtocolId: variants[0].id });
    }
  }, [focusedProtocolId, variants]);

  useEffect(() => {
    // If there is only one variant, skip the variant selection step.
    if (currentStep === "protocol-variant-button" && variants.length <= 1) {
      useCalendarTourStore.setState({
        currentStep: "schedule-item",
      });
    }
  }, [currentStep, variants.length]);

  if (variants.length <= 1) {
    return null;
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <h4 className="font-semibold">Difficulties</h4>
      <div className="flex w-full flex-col gap-2">
        {variants
          .sort(
            (a, b) =>
              order.findIndex((o) => o.name === a.variant) -
              order.findIndex((o) => o.name === b.variant),
          )
          .map(({ id, variant, baseProtocolId, description }) => {
            const isFocused = id === focusedProtocolId;
            const variantIcon = order.find((o) => o.name === variant)?.icon;

            return (
              <button
                key={id}
                data-test="protocol-variant-button"
                data-tour-step="protocol-variant-button"
                onClick={() => {
                  setCalendarTourStep("schedule-item");
                  useProtocolStore.setState({
                    focusedProtocolId: id,
                    focusedBaseProtocolId: baseProtocolId,
                  });
                }}
                className={twMerge(
                  "flex h-fit w-full flex-col border text-base-placeholder hover:bg-base-200",
                  "items-start justify-start gap-1 rounded-lg",
                  "px-4 py-3",
                  isFocused && "border-base-content text-base-content",
                )}
              >
                <div className="flex w-full flex-row items-center justify-between">
                  <div className="flex flex-row items-start gap-2">
                    <FontAwesomeIcon
                      icon={variantIcon ?? faGaugeSimple}
                      className="mt-1"
                    />
                    <h6 className="font-medium">
                      {capitalizeFirstChar(variant)}
                    </h6>
                  </div>
                  <FontAwesomeIcon
                    icon={isFocused ? faCheckCircle : faCircle}
                  />
                </div>
                <p className="text-left text-xs font-light">{description}</p>
              </button>
            );
          })}
      </div>
    </div>
  );
};

const order = [
  { name: "Novice", icon: faGaugeSimpleMin },
  { name: "Beginner", icon: faGaugeSimpleLow },
  { name: "Intermediate", icon: faGaugeSimple },
  { name: "Advanced", icon: faGaugeSimpleHigh },
  { name: "Pro", icon: faGaugeSimpleMax },
] as const;

export default ProtocolVariantSelector;
