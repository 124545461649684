import { useEffect, useState } from "react";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { captureException } from "@sentry/nextjs";

import { useAuth } from "@acme/auth";
import { Button } from "@acme/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@acme/ui/dialog";
import { toast } from "@acme/ui/sonner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@acme/ui/tooltip";

import { useProtocolStore, useSideDrawerStore } from "~/store/calendar";
import { api } from "~/utils/api";
import { useBreakpoint } from "~/utils/breakpoints";
import {
  setCalendarTourStep,
  useCalendarTourStore,
} from "../../calendar/CalendarTour";

export const SaveUserProtocolButton = () => {
  const trpc = api.useContext();
  const isDesktop = useBreakpoint("md");
  const [showDialog, showConfirmationDialog] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [cannotSaveReason, setCannotSaveReason] = useState("");
  const finishCalendarTour = api.user.finishCalendarTour.useMutation();
  const { userId } = useAuth();
  const { setMode } = useSideDrawerStore();
  const {
    activitySchedules,
    stagedActivitySchedules,
    focusedBaseProtocolId,
    focusedProtocolId,
    baseProtocols,
    userProtocols,
    stagedUserProtocols,
    getImplementedVariant,
    getFocusedSchedules,
    getFocusedUserProtocol,
  } = useProtocolStore();

  const focusedProtocolIsImplemented = Object.values(userProtocols).some(
    (userProtocol) => userProtocol.protocolId === focusedProtocolId,
  );

  useEffect(() => {
    const userProtocolId = getFocusedUserProtocol()?.id;
    const schedules = Object.values(stagedActivitySchedules).filter(
      (schedule) => schedule.userProtocolId === userProtocolId,
    );

    const hasInvalidStartTime = schedules.some((s) => s.startTime === "");
    if (hasInvalidStartTime) {
      setCannotSaveReason("Please set a start time for each activity.");
    } else {
      setCannotSaveReason("");
    }

    setCanSave(!hasInvalidStartTime && schedules.length > 0);
  }, [
    activitySchedules,
    focusedProtocolId,
    getFocusedUserProtocol,
    stagedActivitySchedules,
    stagedUserProtocols,
    userProtocols,
  ]);

  const upsertUserProtocol = api.userProtocol.upsertUserProtocol.useMutation({
    async onSettled(data, error, params) {
      if (!error) {
        const verb = params.type === "create" ? "added" : "updated";
        toast.success(`Successfully ${verb} protocol.`, {
          position: "bottom-center",
        });
        await Promise.all([
          trpc.activityEvent.findActivityEvents.invalidate(),
          trpc.userProtocol.getMyProtocols.invalidate(),
        ]);
        setMode("my-protocols", { force: true });
        useProtocolStore.setState((store) => {
          const persistedStagedSchedules = Object.values({
            ...store.stagedActivitySchedules,
          }).filter((schedule) => schedule?.userProtocolId !== params.id);

          return {
            ...store,
            focusedBaseProtocolId: null,
            focusedProtocolId: null,
            stagedActivitySchedules: persistedStagedSchedules.reduce(
              (acc, schedule) => ({
                ...acc,
                [schedule.id]: schedule,
              }),
              {},
            ),
          };
        });

        const isTourEnabled = useCalendarTourStore.getState().enabled;
        if (isTourEnabled) {
          finishCalendarTour.mutate();
        }
        // need to set the furthest completed step to 6 because the useEffect in CalendarTour.tsx
        // will register a change in sidedrawer mode and set the step to 0 before the stepIndex-change
        // timeout is reached
        useCalendarTourStore.setState({ completedSteps: [] });
        setCalendarTourStep("calendar-block");
        if (isTourEnabled && !isDesktop) {
          setMode("closed"); // go to calendar view on mobile to see new activities
        }
      } else {
        captureException(error);
        toast.error("Something went wrong. Please try again.");
      }
    },
  });

  const validateSave = () => {
    if (!focusedBaseProtocolId || !focusedProtocolId) {
      captureException(
        new Error(
          `Failed to save protocol. Missing: focusedBaseProtocolId or focusedProtocolId`,
        ),
      );
      toast.error("Something went wrong. Please refresh the page try again.");
      return;
    }

    const implementedVariant = getImplementedVariant(focusedBaseProtocolId);
    const userProtocolForImplementedVariant = Object.values(userProtocols).find(
      (userProtocol) => userProtocol.protocolId === implementedVariant?.id,
    );

    if (
      implementedVariant &&
      userProtocolForImplementedVariant?.isImplemented &&
      implementedVariant.id !== focusedProtocolId
    ) {
      showConfirmationDialog(true);
      return;
    }

    handleSave();
  };

  const handleSave = () => {
    const userProtocolId = getFocusedUserProtocol()?.id;

    const schedules = getFocusedSchedules();

    if (
      !userId ||
      !userProtocolId ||
      !focusedProtocolId ||
      !focusedBaseProtocolId
    ) {
      const missingParameters = [
        "userProtocolId",
        "focusedProtocolId",
        "focusedBaseProtocolId",
      ]
        .filter((param) => !eval(param))
        .join(", ");

      captureException(
        new Error(`Failed to save protocol. Missing: ${missingParameters}`),
      );

      console.log("missingParameters:", missingParameters);
      toast.error("Something went wrong. Please try again.");
      return;
    }

    upsertUserProtocol.mutate({
      type: focusedProtocolIsImplemented ? "update" : "create",
      id: userProtocolId,
      protocolId: focusedProtocolId,
      activitySchedules: schedules,
      baseProtocolId: focusedBaseProtocolId,
    });
  };

  return (
    <>
      <TooltipProvider>
        <Tooltip delayDuration={400}>
          <TooltipTrigger asChild>
            <div>
              <Button
                data-test="save-user-protocol-button"
                data-tour-step="save-user-protocol-button"
                disabled={!canSave}
                color="brand"
                onClick={validateSave}
                leftIcon={faCheck}
              >
                {focusedProtocolIsImplemented ? "Save" : "Add to routine"}
              </Button>
            </div>
          </TooltipTrigger>
          {cannotSaveReason && (
            <TooltipContent>{cannotSaveReason}</TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
      <Dialog
        onOpenChange={() => showConfirmationDialog(false)}
        open={showDialog}
      >
        <DialogContent
          data-test="confirmation-dialog"
          className="max-h-[100svh] overflow-x-hidden bg-base-100 sm:max-w-[425px]"
        >
          <DialogHeader>
            <DialogTitle className="text-xl">
              Confirm variation change
            </DialogTitle>
          </DialogHeader>
          <DialogDescription className="text-md">
            <div className="flex flex-col gap-2">
              <p>
                You are already implementing another variation of{" "}
                <strong>
                  {focusedBaseProtocolId
                    ? baseProtocols[focusedBaseProtocolId]?.name
                    : "this protocol"}
                </strong>{" "}
                in your routine.
              </p>
              <p>
                If you continue, scheduled activities for your previous
                variation will be removed. This cannot be undone.
              </p>
            </div>
          </DialogDescription>
          <DialogFooter className="mt-4 flex flex-row justify-end gap-2">
            <Button
              data-test="confirmation-dialog-cancel-button"
              variant={"outline"}
              onClick={() => showConfirmationDialog(false)}
            >
              Cancel
            </Button>
            <Button
              data-test="confirmation-dialog-confirm-button"
              leftIcon={faCheck}
              color="brand"
              onClick={handleSave}
            >
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
