import type { PillarType } from "@acme/db";
import { colors } from "@acme/tailwind-config";

export const pillarColors = {
  recovery: "text-recovery",
  nutrition: "text-nutrition",
  mindfulness: "text-mindfulness",
  fitness: "text-fitness",
};

export const pillarBackgroundColors = {
  recovery: "bg-recovery-subtle",
  nutrition: "bg-nutrition-subtle",
  mindfulness: "bg-mindfulness-subtle",
  fitness: "bg-fitness-subtle",
};

export const solidPillarBackgroundColors = {
  recovery: "bg-recovery",
  nutrition: "bg-nutrition",
  mindfulness: "bg-mindfulness",
  fitness: "bg-fitness",
};

export const getPillarColor = (pillar: string) => {
  switch (pillar) {
    case "recovery":
      return "text-recovery";
    case "nutrition":
      return "text-nutrition";
    case "mindfulness":
      return "text-mindfulness";
    case "fitness":
      return "text-fitness";
    default:
      return "text-base-content";
  }
};

export const getPillarBgColor = (pillar: string) => {
  switch (pillar) {
    case "recovery":
      return "bg-recovery-bg/20";
    case "nutrition":
      return "bg-nutrition-bg/20";
    case "mindfulness":
      return "bg-mindfulness-bg/20";
    case "fitness":
      return "bg-fitness-bg/20";
    default:
      return "bg-base-content/20";
  }
};

export const getPillarBorderColor = (pillar: string) => {
  switch (pillar) {
    case "recovery":
      return "border-recovery";
    case "nutrition":
      return "border-nutrition";
    case "mindfulness":
      return "border-mindfulness";
    case "fitness":
      return "border-fitness";
    default:
      return "border-base-content";
  }
};

export const getPillarBorderColorSubtle = (pillar: string) => {
  switch (pillar) {
    case "recovery":
      return "border-recovery/50";
    case "nutrition":
      return "border-nutrition/50";
    case "mindfulness":
      return "border-mindfulness/50";
    case "fitness":
      return "border-fitness/50";
    default:
      return "border-base-content/50";
  }
};

export const getFillByPillar = (pillar: PillarType) => {
  switch (pillar) {
    case "fitness":
      return colors["fitness-bg"];
    case "nutrition":
      return colors["nutrition-bg"];
    case "recovery":
      return colors["recovery-bg"];
    case "mindfulness":
      return colors["mindfulness-bg"];
    default:
      return "black";
  }
};
