import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";

import { activityIconNames } from "~/utils/icons";

export const ActivityIcon = ({ icon, className }: FontAwesomeIconProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  const isValidIcon = activityIconNames.includes(icon as any);

  return (
    <FontAwesomeIcon
      icon={isValidIcon ? icon : "walking"}
      className={twMerge("h-4 w-4", className)}
    />
  );
};
