import { useIsFetching } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";

import { api } from "~/utils/api";
import { useDateRangeStore, useProtocolStore } from ".";
import type { ProtocolStoreState } from ".";

export const useLoadActivityEvents = () => {
  const dateRange = useDateRangeStore();
  api.activityEvent.findActivityEvents.useQuery(dateRange, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const activityEventsObj = data.reduce(
        (acc, activityEvent) => {
          acc[activityEvent.id] = activityEvent;
          return acc;
        },
        {} as ProtocolStoreState["activityEvents"],
      );

      const activities = data.map((activityEvent) => activityEvent.activity);

      useProtocolStore.setState((state) => ({
        ...state,
        activities: {
          ...state.activities,
          ...activities.reduce(
            (acc, activity) => {
              acc[activity.id] = {
                ...state.activities[activity.id],
                // the incoming activity is missing default days, but we don't need it here because
                // these activities only show up in the context of a calendar block, not in the activity scheduler
                ...(activity as ProtocolStoreState["activities"][string]),
              };
              return acc;
            },
            {} as ProtocolStoreState["activities"],
          ),
        },
        activityEvents: {
          // ...state.activityEvents, // disabled for now while we fight prod bugs
          ...activityEventsObj,
        },
      }));
    },
  });
};

/**
 * Loads all implemented protocols and schedules for the current user, along
 * with all related base protocols and activities. The fetched data is stored in the
 * global protocols store.
 */
export const useLoadMyProtocols = () => {
  api.userProtocol.getMyProtocols.useQuery(undefined, {
    refetchOnWindowFocus: false,
    onSettled: (_data) => {
      if (!_data) return;
      const protocols = _data.map((d) => d.protocol);
      const schedules = _data.flatMap((d) => d.activitySchedules);

      const userProtocolObj = _data.reduce(
        (acc, userProtocol) => {
          acc[userProtocol.protocol.id] = userProtocol;
          return acc;
        },
        {} as ProtocolStoreState["userProtocols"],
      );

      const protocolsObj = protocols.reduce(
        (acc, protocol) => {
          acc[protocol.id] = protocol;
          return acc;
        },
        {} as ProtocolStoreState["protocols"],
      );

      const activitiesObj = protocols
        .flatMap((protocol) => protocol.activities)
        .reduce(
          (acc, activity) => {
            acc[activity.id] = activity;
            return acc;
          },
          {} as ProtocolStoreState["activities"],
        );

      const schedulesObj = schedules.reduce(
        (acc, schedule) => {
          acc[schedule.id] = schedule;

          return acc;
        },
        {} as ProtocolStoreState["activitySchedules"],
      );

      const baseProtocolsObj = protocols
        .map((protocol) => protocol.baseProtocol)
        .reduce(
          (acc, baseProtocol) => {
            acc[baseProtocol.id] = {
              ...baseProtocol,
              pillar: baseProtocol.protocolType.pillar.name,
              protocolType: baseProtocol.protocolType,
              expert: baseProtocol.expert ?? {
                name: "Vital Routines",
                image: "/experts/vital-routines.png",
                description: "Vital Routines",
                subtitle: "Vital Routines",
              },
            };
            return acc;
          },
          {} as ProtocolStoreState["baseProtocols"],
        );

      useProtocolStore.setState((state) => ({
        ...state,
        activitySchedules: { ...schedulesObj },
        userProtocols: { ...userProtocolObj },
        protocols: { ...state.protocols, ...protocolsObj },
        activities: { ...state.activities, ...activitiesObj },
        baseProtocols: { ...state.baseProtocols, ...baseProtocolsObj },
      }));
    },
  });
};

/**
 * Loads all base protocols, along with all related protocols and activities.
 * Primarily used for the protocol catalog and browsing new protocols to add
 * to your routine. The fetched data is stored in the global protocols store.
 */
export const useLoadAllBaseProtocols = () => {
  api.protocol.getAllBaseProtocols.useQuery(undefined, {
    refetchOnWindowFocus: false,
    onSuccess: ({ baseProtocols }) => {
      const baseProtocolsObj = baseProtocols.reduce(
        (acc, baseProtocol) => {
          acc[baseProtocol.id] = {
            ...baseProtocol,
            pillar: baseProtocol.protocolType.pillar.name,
            protocolType: baseProtocol.protocolType,
            expert: baseProtocol.expert ?? {
              name: "Vital Routines",
              image: "/experts/vital-routines.png",
              description: "Vital Routines",
              subtitle: "Vital Routines",
            },
          };
          return acc;
        },
        {} as ProtocolStoreState["baseProtocols"],
      );

      const protocolsObj = baseProtocols
        .flatMap((baseProtocol) => baseProtocol.variants)
        .reduce(
          (acc, protocol) => {
            acc[protocol.id] = protocol;
            return acc;
          },
          {} as ProtocolStoreState["protocols"],
        );

      const activitiesObj = baseProtocols
        .flatMap((baseProtocol) => baseProtocol.variants)
        .flatMap((protocol) => protocol.activities)
        .reduce(
          (acc, activity) => {
            acc[activity.id] = activity;
            return acc;
          },
          {} as ProtocolStoreState["activities"],
        );

      const protocolTypesObj = baseProtocols
        .flatMap((baseProtocol) => baseProtocol.protocolType)
        .reduce(
          (acc, protocolType) => {
            acc[protocolType.id] = protocolType;
            return acc;
          },
          {} as ProtocolStoreState["protocolTypes"],
        );

      const pillarsObj = baseProtocols
        .flatMap((baseProtocol) => baseProtocol.protocolType.pillar)
        .reduce(
          (acc, pillar) => {
            acc[pillar.id] = pillar;
            return acc;
          },
          {} as ProtocolStoreState["pillars"],
        );

      useProtocolStore.setState((state) => ({
        ...state,
        baseProtocols: { ...state.baseProtocols, ...baseProtocolsObj },
        protocols: { ...state.protocols, ...protocolsObj },
        activities: { ...state.activities, ...activitiesObj },
        protocolTypes: { ...state.protocolTypes, ...protocolTypesObj },
        pillars: { ...state.pillars, ...pillarsObj },
      }));
    },
  });
};

export function useIsFetchingProtocols() {
  // See if a query is fetching
  const baseProtocolsKey = getQueryKey(
    api.protocol.getAllBaseProtocols,
    undefined,
    "query",
  );

  const myProtocolsKey = getQueryKey(
    api.userProtocol.getMyProtocols,
    undefined,
    "query",
  );

  const isFetchingBaseProtocols = useIsFetching(baseProtocolsKey);
  const isFetchingMyProtocols = useIsFetching(myProtocolsKey);

  return Boolean(isFetchingBaseProtocols || isFetchingMyProtocols);
}

export const useIsFetchingActivityEvents = () => {
  const activityEventsKey = getQueryKey(
    api.activityEvent.findActivityEvents,
    undefined,
    "query",
  );

  const isFetchingActivityEvents = useIsFetching(activityEventsKey);

  return Boolean(isFetchingActivityEvents);
};
