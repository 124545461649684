import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import { ScrollArea } from "@acme/ui/scroll-area";

import { useSideDrawerStore } from "~/store/calendar";
import { ProtocolCustomization } from "../protocols/details/ProtocolCustomization";
import { MyProtocolStack } from "../protocols/MyProtocolStack";

const OPEN_WIDTH = 680;
const ANIMATION_DURATION = 500;
const TW_ANIMATION_DURATION = `duration-500`;

const CalendarSideDrawer = () => {
  const { mode } = useSideDrawerStore();
  const containerRef = useRef<HTMLDivElement>(null);

  const resetScrollPosition = () => {
    // wihtout the setTimeout, the scroll position is not reset consistently
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    });
  };

  useEffect(() => {
    if (mode === "protocol-customization") {
      resetScrollPosition();
    }
  }, [mode]);

  return (
    <div className="sticky top-0 z-40 hidden max-h-screen bg-base-100 md:block">
      <ScrollArea
        ref={containerRef}
        style={{ width: mode !== "closed" ? OPEN_WIDTH : 0 }}
        className={twMerge(
          `flex h-full flex-col overflow-y-auto shadow-2xl transition-all ${TW_ANIMATION_DURATION} `,
          mode !== "closed" ? "border-l border-base-200" : "border-transparent",
        )}
      >
        <div className="p-8 pt-8">
          <ActiveComponentController />
        </div>
      </ScrollArea>
    </div>
  );
};

const useSlideDirection = () => {
  const { mode, previousMode } = useSideDrawerStore();

  const slideOrder = {
    closed: 0,
    catalog: 1,
    "my-protocols": 2,
    "protocol-customization": 3,
  };

  const slideDirection = (() => {
    const baseAnimation = "animate-in fade-in duration-500";
    if (!previousMode) {
      return `${baseAnimation} slide-in-from-bottom-5`;
    } else if (slideOrder[mode] > slideOrder[previousMode]) {
      return `${baseAnimation} slide-in-from-right-5`;
    } else {
      return `${baseAnimation} slide-in-from-left-5`;
    }
  })();

  return slideDirection;
};

const ActiveComponentController = () => {
  const { mode, isOpening } = useSideDrawerStore();
  const slideDirection = useSlideDirection();

  if (isOpening) return null;

  if (mode === "my-protocols") {
    return (
      <div key={mode} className={twMerge(slideDirection, "flex grow")}>
        <MyProtocolStack />
      </div>
    );
  }

  if (mode === "protocol-customization") {
    return (
      <div key={mode} className={slideDirection}>
        <ProtocolCustomization />
      </div>
    );
  }

  return null;
};

export { ANIMATION_DURATION, CalendarSideDrawer };
