import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { create } from "zustand";

export type DateRangeMode = "week" | "month" | "year";

dayjs.extend(minMax);

const FORMAT = "YYYY-MM-DD";

interface DateRangeStoreState {
  from: Date;
  to: Date;
  mode: DateRangeMode;
  setMode: (mode: DateRangeMode) => void;
  getIntervalDateRanges: () => { from: Date; to: Date }[];
}

export const useDateRangeStore = create<DateRangeStoreState>((set, get) => ({
  mode: "week", // default mode
  from: dayjs(new Date()).startOf("week").toDate(),
  to: dayjs(new Date()).endOf("week").toDate(),
  getIntervalDateRanges: () => {
    const { from, to, mode } = get();
    const dateRangeModeToIntervalMode = {
      week: "day",
      month: "week",
      year: "month",
    } as const;

    let currentDate = dayjs(from);
    const endDate = dayjs(to);
    const intervals: { from: Date; to: Date }[] = [];

    while (currentDate <= endDate) {
      const intervalEnd = dayjs.min(
        currentDate.endOf(dateRangeModeToIntervalMode[mode]),
        endDate,
      );
      intervals.push({
        from: currentDate.toDate(),
        to: intervalEnd.toDate(),
      });
      currentDate = intervalEnd.add(1, "day").startOf("day");
    }

    return intervals;
  },
  setMode: (mode: DateRangeMode) => {
    set((state) => {
      let newFrom: Dayjs, newTo: Dayjs;
      switch (mode) {
        case "week":
          newFrom = dayjs(new Date()).startOf("week");
          newTo = dayjs(new Date()).endOf("week");
          break;
        case "month":
          newFrom = dayjs(new Date()).startOf("month");
          newTo = dayjs(new Date()).endOf("month");
          break;
        case "year":
          newFrom = dayjs(new Date()).startOf("year");
          newTo = dayjs(new Date()).endOf("year");
          break;
        default:
          newFrom = dayjs(state.from);
          newTo = dayjs(state.to);
      }
      return {
        ...state,
        mode,
        from: newFrom.toDate(),
        to: newTo.toDate(),
      };
    });
  },
}));

const validModes: DateRangeMode[] = ["week", "month", "year"];

/**
 * Syncs the URL query params with the date range store.
 * - if the "mode" query param is invalid, it will default to "week"
 * - if the "from" or "to" query params are invalid, it will default to the current date
 * - if the "from" and "to" dates are valid, but do not adhere to the mode, it set the "to" and "from"
 * dates to the start and end of the mode period
 */
export const useSyncUrlWithDateRangeStore = () => {
  const [hasSynced, setHasSynced] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const query = router.query;

    const mode = validModes.includes(query.mode as DateRangeMode)
      ? (query.mode as DateRangeMode)
      : "week";

    const parseDate = (dateStr: string | string[] | undefined): Dayjs => {
      if (typeof dateStr === "string") {
        const parsedDate = dayjs(dateStr, FORMAT);
        if (parsedDate.isValid()) return parsedDate;
      }
      // Fallback to current date if error parsing
      return dayjs().startOf(mode);
    };

    let fromParsed = parseDate(query.from);
    let toParsed = parseDate(query.to);

    // Adjust `to` based on `mode` and `from` if `from` is after `to`
    if (fromParsed) {
      switch (mode) {
        case "week":
          fromParsed = fromParsed.startOf("week");
          toParsed = fromParsed.endOf("week");
          break;
        case "month":
          fromParsed = fromParsed.startOf("month");
          toParsed = fromParsed.endOf("month");
          break;
        case "year":
          fromParsed = fromParsed.startOf("year");
          toParsed = fromParsed.endOf("year");
          break;
      }
    }

    useDateRangeStore.setState({
      mode,
      from: fromParsed.toDate(),
      to: toParsed.toDate(),
    });
    setHasSynced(true);
  }, [router.query]);

  return { hasSynced };
};

export const useUpdateDateRange = () => {
  const router = useRouter();
  const { from, to, mode } = useDateRangeStore();

  const updateDateRange = async ({ from, to }: { from: Date; to: Date }) => {
    await router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          mode,
          from: dayjs(from).format(FORMAT),
          to: dayjs(to).format(FORMAT),
        },
      },
      undefined,
      { shallow: true },
    );
  };

  const increment = () => {
    return updateDateRange({
      from: dayjs(from).add(1, mode).startOf(mode).toDate(),
      to: dayjs(to).add(1, mode).endOf(mode).toDate(),
    });
  };

  const decrement = () => {
    return updateDateRange({
      from: dayjs(from).subtract(1, mode).startOf(mode).toDate(),
      to: dayjs(to).subtract(1, mode).endOf(mode).toDate(),
    });
  };

  const updateMode = async (newMode: DateRangeMode) => {
    await router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          mode: newMode,
          from: dayjs().startOf(newMode).format(FORMAT),
          to: dayjs().endOf(newMode).format(FORMAT),
        },
      },
      undefined,
      { shallow: true },
    );
  };

  return { increment, decrement, updateDateRange, updateMode };
};
