import plugin from "tailwindcss/plugin";

export const scrollBarUtils = plugin(function ({ addUtilities }) {
  addUtilities({
    ".scrollbar-none": {
      /* Hide scrollbar for Chrome, Safari and Opera */
      "&::-webkit-scrollbar": {
        display: "none",
      },
      /* Hide scrollbar for IE, Edge and Firefox */
      "-ms-overflow-style": "none", // IE and Edge
      "scrollbar-width": "none", // Firefox
    },
  });
});
