import react, { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";

import type { ActivityEvent } from "@acme/db";
import { ActivityEvent as EventComponent } from "@acme/ui/app/activity-event";
import { Dialog, DialogContent, DialogTrigger } from "@acme/ui/dialog";
import { Slot } from "@acme/ui/slot";
import { toast } from "@acme/ui/sonner";

import { useProtocolStore, useSideDrawerStore } from "~/store/calendar";
import { useToggleCompletedAt } from "./ActivityEventToggle";

interface ActivityDetailsModalProps {
  isOpen?: boolean;
  isPreview?: boolean;
  event: Pick<ActivityEvent, "activityId" | "completedAt"> & {
    id?: string;
    scheduledFor: Date | null;
  };
  children?: react.ReactNode;
}

export const ActivityDetailsModal = (props: ActivityDetailsModalProps) => {
  const { isPreview, event } = props;
  const { activities, protocols, baseProtocols } = useProtocolStore();
  const { toggle } = useToggleCompletedAt(isPreview ? "" : event.id!);
  const [open, setOpen] = react.useState(props.isOpen ?? false);
  const { setMode } = useSideDrawerStore();
  const { clearActivityEventFromUrl } = useActivityEventFromUrl();

  useEffect(() => {
    setOpen(props.isOpen ?? false);
  }, [props.isOpen, setOpen]);

  const activity = activities[props.event.activityId]!;
  const protocol = protocols[activity.protocolId]!;

  if (!protocol) {
    return (
      <Slot onClick={() => toast("Activity details outdated", { icon: "ℹ️" })}>
        {props.children}
      </Slot>
    );
  }

  const baseProtocol = baseProtocols[protocol.baseProtocolId]!;
  const expert = baseProtocol.expert;

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          clearActivityEventFromUrl();
        }
      }}
    >
      <DialogTrigger onClick={() => setOpen(!open)} asChild>
        {props.children}
      </DialogTrigger>

      <DialogContent
        data-test="activity-details-dialog"
        className="p-0 sm:rounded-2xl sm:p-0 md:max-w-[500px] md:p-0"
        onClick={(e) => e.stopPropagation()}
        hideCloseButton={true}
      >
        <EventComponent
          className="p-0"
          isPreview={isPreview}
          event={event}
          activity={activity}
          protocol={{ name: baseProtocol.name }}
          expert={expert}
          onComplete={() => {
            if (props.event.id) {
              toggle.mutate({ activityEventId: props.event.id });
            }
          }}
          onProtocolClick={() => {
            useProtocolStore.setState({
              focusedBaseProtocolId: baseProtocol.id,
              focusedProtocolId: protocol.id,
            });
            setMode("protocol-customization");
          }}
          onClose={() => setOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
};

export const useActivityEventFromUrl = () => {
  const [activityEvent, setActivityEvent] = useState<ActivityEvent | null>(
    null,
  );
  const router = useRouter();
  const activityId = router.query.activityId as string | undefined;
  const { activityEvents, activities } = useProtocolStore();

  useEffect(() => {
    const activityEvent = Object.values(activityEvents).find(
      (e) => e.activityId === activityId,
    );
    setActivityEvent(activityEvent ?? null);
  }, [activityEvents, activities, activityId]);

  const clearActivityEventFromUrl = useCallback(() => {
    const { activityId, ...query } = router.query;
    void router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true },
    );
  }, [router]);

  return {
    activityId,
    activityEvent,
    clearActivityEventFromUrl,
  };
};
