// CustomLegend.tsx
import type { DataPoint } from ".";
import { solidPillarBackgroundColors } from "../../../components/custom/utils";
import { cn } from "../../../lib/utils";

export const CustomLegend = ({ data }: { data: DataPoint[] }) => {
  const protocols = Array.from(
    new Set(data.flatMap((d) => d.values.map((v) => v.protocolName))),
  );

  return (
    <div className="flex gap-4">
      {protocols.map((protocol, index) => (
        <div key={`legend-item-${index}`} className="flex items-center gap-2">
          <span className="text-xs">{protocol}</span>
          <div
            className={cn(
              "h-3 w-3 rounded-full",
              solidPillarBackgroundColors[data[0]!.values[index]!.pillar],
            )}
          ></div>
        </div>
      ))}
    </div>
  );
};
