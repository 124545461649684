import * as React from "react";

import { cn } from "../../lib/utils";

const className = {
  color: {
    default: {
      default: "bg-base-placeholder text-background",
      subtle: "bg-muted text-foreground",
      outline: "text-base-placeholder",
    },
    destructive: {
      default: "bg-destructive text-destructive-foreground",
      subtle:
        "bg-destructive/[0.12] dark:bg-destructive/[0.18] text-destructive",
      outline: "border-destructive-subtle text-destructive",
    },
    recovery: {
      default: "bg-recovery text-recovery-foreground",
      subtle: "bg-recovery-subtle text-recovery",
      outline: "border-recovery-subtle text-recovery",
    },
    nutrition: {
      default: "bg-nutrition text-nutrition-foreground",
      subtle: "bg-nutrition-subtle text-nutrition",
      outline: "border-nutrition-subtle text-nutrition",
    },
    fitness: {
      default: "bg-fitness text-fitness-foreground",
      subtle: "bg-fitness-subtle text-fitness",
      outline: "border-fitness-subtle text-fitness",
    },
    mindfulness: {
      default: "bg-mindfulness text-mindfulness-foreground",
      subtle: "bg-mindfulness-subtle text-mindfulness",
      outline: "border-mindfulness-subtle text-mindfulness",
    },
  },
  variant: {
    default: "border-transparent",
    subtle: "border-transparent",
    outline: "border",
  },
};

interface BadgeVariants {
  color: keyof typeof className.color;
  variant: keyof typeof className.variant;
  interactive: boolean;
}

const badgeVariants = ({ color, variant, interactive }: BadgeVariants) =>
  cn(
    "inline-flex items-center rounded-full border px-2.5 py-1 rounded text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    className.color[color][variant],
    className.variant[variant],
    interactive &&
      "hover:opacity-70 cursor-pointer transition-opacity duration-200",
  );

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: keyof typeof className.color;
  variant?: keyof typeof className.variant;
}

function Badge({ className, color, variant, ...props }: BadgeProps) {
  return (
    <div
      className={cn(
        badgeVariants({
          color: color ?? "default",
          variant: variant ?? "default",
          interactive: !!props.onClick,
        }),
        className,
      )}
      {...props}
    />
  );
}

/* for use in button.stories.tsx */
const colors = Object.keys(className.color) as (keyof typeof className.color)[];
const variants = Object.keys(
  className.variant,
) as (keyof typeof className.variant)[];

export { Badge, badgeVariants, variants, colors };
