"use client";

import { ThemeProvider as NextThemeProvider, useTheme } from "next-themes";

interface Props {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: Props) => {
  return <NextThemeProvider attribute="class">{children}</NextThemeProvider>;
};

export { ThemeProvider, useTheme };
