import { useEffect } from "react";
import { captureException } from "@sentry/nextjs";

import { useUser } from "@acme/auth";

import { api } from "~/utils/api";

export const TimezoneChangeTracker = () => {
  const { user } = useUser();
  const utils = api.useContext();
  const storedTimezone = user?.publicMetadata?.timezone as string;
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const updateTimezone = api.user.updateTimezone.useMutation({
    onSettled(data, error) {
      if (error) {
        console.error(error);
        captureException(error);
      } else {
        void utils.activityEvent.findActivityEvents.invalidate();
      }
    },
  });

  useEffect(() => {
    if (storedTimezone !== currentTimezone) {
      updateTimezone.mutate({ timezone: currentTimezone });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimezone, storedTimezone]);

  return null;
};
