import React from "react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import type { ProtocolBenefits } from "@acme/api";

import { cn } from "../../lib/utils";
import { RadarChart } from "../charts/radar-chart";
import { Icon } from "../ui/icon";
import { ContinuousProgress } from "../ui/progress";
import { ScrollArea } from "../ui/scroll-area";
import { Separator } from "../ui/separator";

interface HealthCategoryBarProps extends React.HTMLAttributes<HTMLDivElement> {
  healthCategory: ProtocolBenefits.HealthCategory;
}

interface HealthGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  healthGroup: ProtocolBenefits.HealthGroup;
}

interface ProtocolBenefitsProps extends React.HTMLAttributes<HTMLDivElement> {
  hasMultipleDataSets?: boolean;
  healthGroups: ProtocolBenefits.HealthGroup[];
}

interface HealthContextProps {
  selectedCategory: ProtocolBenefits.HealthCategory | null;
  setSelectedCategory: (
    category: ProtocolBenefits.HealthCategory | null,
  ) => void;
  hasMultipleDataSets?: boolean;
}

const Context = React.createContext<HealthContextProps>({
  selectedCategory: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedCategory: () => {},
  hasMultipleDataSets: false,
});

const Provider: React.FC<ProtocolBenefitsProps> = ({
  children,
  hasMultipleDataSets,
}) => {
  const [selectedCategory, setSelectedCategory] =
    React.useState<ProtocolBenefits.HealthCategory | null>(null);

  return (
    <Context.Provider
      value={{ selectedCategory, setSelectedCategory, hasMultipleDataSets }}
    >
      {children}
    </Context.Provider>
  );
};

const HealthCategoryBar: React.FC<HealthCategoryBarProps> = (props) => {
  const { healthCategory, className, ...rest } = props;
  const { setSelectedCategory, selectedCategory } = React.useContext(Context);

  return (
    <div
      className={cn(
        "flex  flex-col items-center gap-1 border-b border-b-transparent p-1 hover:border-b-base-placeholder/50",
        "!cursor-pointer transition duration-300 hover:!opacity-100 group-hover:opacity-50",
        selectedCategory && selectedCategory !== healthCategory && "opacity-50",
        selectedCategory === healthCategory && "!opacity-100",
        className,
      )}
      onClick={() => setSelectedCategory(healthCategory)}
      {...rest}
    >
      <div className="flex-1">
        <ContinuousProgress
          className="h-full w-1.5"
          orientation="vertical"
          value={healthCategory.score * 10}
        />
      </div>
      <span className="text-xl">{healthCategory.icon}</span>
    </div>
  );
};

const HealthGroup: React.FC<HealthGroupProps> = (props) => {
  const { className, healthGroup, ...rest } = props;

  return (
    <div
      className={cn(
        "flex min-h-40 flex-1 flex-col items-center gap-3 px-2",
        className,
      )}
      {...rest}
    >
      <span className="text-xs font-light">
        {healthGroup.name.toLocaleLowerCase()}
      </span>
      <div className="group flex grow">
        {Object.values(healthGroup.healthCategories).map((category, index) => (
          <HealthCategoryBar key={index} healthCategory={category} />
        ))}
      </div>
    </div>
  );
};

const LoadingComponent: React.FC = () => {
  return (
    <div className="flex flex-1 items-end">
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={index} className="flex flex-1 flex-col items-center">
          <div className="flex h-40 w-full flex-grow justify-center gap-3">
            {Array.from({ length: 3 }).map((_, idx) => (
              <div key={idx} className="flex flex-col items-center">
                <div
                  className="mx-1 w-2.5 flex-grow animate-pulse rounded-md bg-base-300"
                  style={{
                    animationDelay: `${index * 0.1 + idx * 0.05}s`,
                    animationDuration: "1s",
                  }}
                ></div>
                <div className="mt-2 h-4 w-4 animate-pulse rounded-full bg-base-300"></div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
interface SelectedCategoryTagProps {
  category: ProtocolBenefits.HealthCategory;
  onClear: () => void;
}

const SelectedCategoryTag: React.FC<SelectedCategoryTagProps> = (props) => {
  const { category, onClear } = props;
  return (
    <div
      className="flex cursor-pointer items-center gap-1 rounded-md bg-base-300 px-2 py-1 text-sm"
      onClick={onClear}
    >
      <span>{category.name}</span>
      <Icon className="h-3 w-3" name="close" color="base-500" icon={faTimes} />
    </div>
  );
};

const Content: React.FC<ProtocolBenefitsProps> = (props) => {
  const { className, healthGroups, ...rest } = props;
  const { selectedCategory, setSelectedCategory } = React.useContext(Context);

  return (
    <div
      className={cn(
        "flex flex-col gap-4 rounded-2xl bg-base-200 p-6 px-6",
        className,
      )}
      {...rest}
    >
      <div className="flex h-8 items-center gap-2">
        <h2 className="font-semibold">Benefits</h2>
        {selectedCategory && (
          <SelectedCategoryTag
            category={selectedCategory}
            onClear={() => setSelectedCategory(null)}
          />
        )}
      </div>
      <ScrollArea orientation="horizontal" className="-mx-6 overflow-auto pb-4">
        <div
          className={cn(
            "group flex h-40 min-h-0 flex-1 divide-x divide-base-300 px-2 pb-4",
          )}
        >
          {healthGroups.length === 0 ? (
            <LoadingComponent />
          ) : (
            healthGroups.map((group, index) => (
              <HealthGroup key={index} healthGroup={group} />
            ))
          )}
        </div>
      </ScrollArea>
      <Separator className="-mt-5" />
      <CategoryDetails />
    </div>
  );
};

const CategoryDetails: React.FC = () => {
  const { selectedCategory, hasMultipleDataSets } = React.useContext(Context);

  const radarChartRef = React.useRef<HTMLDivElement>(null);
  const prevSelectedCategoryRef =
    React.useRef<ProtocolBenefits.HealthCategory | null>(null);

  React.useEffect(() => {
    if (
      selectedCategory &&
      !prevSelectedCategoryRef.current &&
      radarChartRef.current
    ) {
      setTimeout(() => {
        radarChartRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 500); // add delay to allow the height to expand before scrolling
    }
    prevSelectedCategoryRef.current = selectedCategory;
  }, [selectedCategory]);

  return (
    <div
      className={cn(
        "overflow-clip",
        selectedCategory
          ? "max-h-[600px] transition-all delay-100 duration-1000"
          : "max-h-8 transition-all duration-500",
      )}
    >
      {selectedCategory ? (
        <div className="flex flex-col gap-2 ">
          <div className="flex gap-4">
            <h3 className="font-semibold">{selectedCategory.name}</h3>
            <span className="flex items-center gap-0.5 rounded border border-primary px-1 text-sm font-light text-primary">
              <span>{Math.min(selectedCategory.score, 10).toFixed(0)}</span>
              <span>/</span>
              <span>10</span>
            </span>
          </div>

          <div ref={radarChartRef} className="h-[400px] md:h-[400px]">
            <RadarChart
              data={selectedCategory.healthIndicators.map((indicator) => ({
                label: `${indicator.icon} ${indicator.name}`,
                hasMultipleDataSets,
                value: indicator.score,
                description: indicator.description ?? "",
                values: indicator.protocolScores.map((score) => ({
                  ...score,
                  description: indicator.description ?? "",
                  value: score.score,
                })),
              }))}
            />
          </div>
        </div>
      ) : (
        <p
          className={cn(
            "h-[400px] text-base-placeholder/70 transition-opacity duration-500",
            selectedCategory ? "opacity-0" : "opacity-100",
          )}
        >
          Select a health category to learn more
        </p>
      )}
    </div>
  );
};

const Root: React.FC<ProtocolBenefitsProps> = (props) => {
  const { hasMultipleDataSets, ...rest } = props;

  return (
    <Provider {...props}>
      <Content {...rest} />
    </Provider>
  );
};

export { HealthCategoryBar, Root as ProtocolBenefits };

export type {
  HealthCategoryBarProps,
  HealthContextProps,
  HealthGroupProps as HealthGroupBarProps,
  ProtocolBenefitsProps,
};
