import * as React from "react";
import * as ProgressPrimitive from "@radix-ui/react-progress";

import { cn } from "../../lib/utils";

const ContinuousProgress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
    orientation?: "vertical" | "horizontal";
  }
>(({ className, value, orientation = "horizontal", ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      "relative overflow-hidden rounded-full",
      orientation === "vertical" ? "h-full w-4" : "h-4 w-full",
      "bg-primary-subtle",
      className,
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className={cn(
        "absolute bg-primary transition-all",
        orientation === "vertical" ? "bottom-0 left-0 right-0" : "left-0 top-0",
        orientation === "vertical" ? "w-full" : "h-full",
      )}
      style={{
        [orientation === "vertical" ? "height" : "width"]: `${value ?? 0}%`,
      }}
    />
  </ProgressPrimitive.Root>
));

ContinuousProgress.displayName = ProgressPrimitive.Root.displayName;

const MultiStepProgress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
    /**
     * Total number of steps in the progress bar.
     */
    totalSteps: number;
    /**
     * Current step in the progress bar. Must be less than or equal to `totalSteps`.
     */
    value: number;
    /**
     * Class name applied to each step in the progress bar.
     */
    stepClassName?: string;
  }
>(({ className, stepClassName, value, totalSteps, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    {...props}
    className={cn("flex gap-2", className)}
  >
    {Array.from({ length: totalSteps }, (_, i) => (
      <ProgressStep
        key={i}
        className={cn(
          "h-3 w-2 rounded-full bg-primary transition-all",
          i < (value ?? 0) ? "opacity-100" : "opacity-20",
          stepClassName,
        )}
      />
    ))}
  </ProgressPrimitive.Root>
));

const ProgressStep = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Indicator>
>(({ className, defaultValue, ...props }, ref) => (
  <ProgressPrimitive.Indicator
    ref={ref}
    className={cn("", className)}
    {...props}
  />
));
ProgressStep.displayName = ProgressPrimitive.Indicator.displayName;

export { ContinuousProgress, MultiStepProgress, ProgressStep };
