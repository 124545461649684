import { useEffect } from "react";
import { useRouter } from "next/router";
import {
  faArrowUpRight,
  faChevronLeft,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";

import analytics from "@acme/segment/client";
import { Button } from "@acme/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@acme/ui/dialog";

import { useShowCalendarTourStep } from "~/components/calendar/CalendarTour";
import { NextImage } from "~/components/core/NextImageReact";
import { cn } from "~/lib/utils";
import { useProtocolStore, useSideDrawerStore } from "~/store/calendar";
import { api } from "~/utils/api";
import ProtocolActivitiesScheduler from "./ProtocolActivitiesScheduler";
import ProtocolVariantSelector from "./ProtocolVariantSelector";
import { RemoveUserProtocolButton } from "./RemoveUserProtocolButton";
import { SaveUserProtocolButton } from "./SaveUserProtocolButton";

const ProtocolCustomization = (props: { className?: string }) => {
  const { setMode, showDiscardChangesDialog } = useSideDrawerStore();
  const vitalUserId = api.user.getVitalUserId.useQuery().data;
  const router = useRouter();
  const fromUrl = router.query.fromUrl as string | undefined;

  useShowCalendarTourStep("protocol-variant-button");

  const {
    focusedBaseProtocolId,
    focusedProtocolId,
    protocols,
    baseProtocols,
    userProtocols,
    resetAllSchedules,
  } = useProtocolStore();

  const focusedProtocol = focusedProtocolId
    ? protocols[focusedProtocolId]
    : null;
  const focusedBaseProtocol = focusedBaseProtocolId
    ? baseProtocols[focusedBaseProtocolId]
    : focusedProtocol
    ? baseProtocols[focusedProtocol.baseProtocolId]
    : null;

  const focusedProtocolIsImplemented = Object.values(userProtocols).some(
    (userProtocol) => userProtocol.protocolId === focusedProtocolId,
  );

  const expert = focusedBaseProtocol?.expert;
  const variants = Object.values(protocols).filter(
    (protocol) => protocol.baseProtocolId === focusedBaseProtocol?.id,
  );

  const handleClose = () => {
    resetAllSchedules();
    useProtocolStore.setState({
      focusedBaseProtocolId: null,
      focusedProtocolId: null,
    });
    setMode("my-protocols");
  };

  useEffect(() => {
    if (
      !focusedProtocolId ||
      !focusedBaseProtocol ||
      !expert ||
      !focusedProtocol ||
      !vitalUserId
    ) {
      return;
    }

    void analytics.track({
      event: "protocol_viewed",
      userId: vitalUserId,
      properties: {
        id: focusedProtocolId,
        name: focusedBaseProtocol.name,
        variant: focusedProtocol.variant,
        expertName: expert.name,
      },
    });
  }, [
    expert,
    focusedBaseProtocol,
    focusedProtocol,
    focusedProtocolId,
    vitalUserId,
  ]);

  if (!focusedBaseProtocol || !expert) {
    setMode("my-protocols");
    return null;
  }

  return (
    <>
      <div className={cn("flex flex-col gap-8", props.className)}>
        <div className="relative -m-4 md:-m-12 md:mb-0">
          <div className="dark absolute z-20 flex h-full w-full flex-col items-stretch justify-between gap-4 p-5 text-white md:p-10">
            <div className="flex justify-between ">
              <Button
                className={cn("md:hidden", !fromUrl && "hidden")}
                size={"icon"}
                variant={"outline"}
                leftIcon={faChevronLeft}
                onClick={() => router.push(fromUrl!)}
              ></Button>
              <Button
                variant="ghost"
                onClick={() => setMode("my-protocols")}
                className="ml-auto h-auto rounded p-0 font-medium"
              >
                Done
              </Button>
            </div>
            <div className="flex w-full flex-row items-end justify-between ">
              <div className="flex grow flex-col items-start gap-0.5">
                <h3 className="break-words text-xl font-semibold">
                  {focusedBaseProtocol.name}
                </h3>
                <div className="flex items-center gap-2 text-base-placeholder">
                  Protocol customization
                </div>
              </div>
              <Button
                onClick={() =>
                  router.push(`/protocols/${focusedBaseProtocol.id}`)
                }
                variant={"subtle"}
                className={cn("shrink-0 ", fromUrl && "max-md:hidden")}
                rightIcon={faArrowUpRight}
              >
                View in Catalog
              </Button>
            </div>
          </div>
          <div className="absolute inset-0 z-10 bg-black opacity-70"></div>
          <NextImage
            src={`/protocols/${focusedBaseProtocol.name}.jpg`}
            alt={`${focusedBaseProtocol.name} protocol banner`}
            className="aspect-[500/180] min-h-[150px] w-full bg-center object-cover md:aspect-[640/140]"
            height={220}
            width={640}
          />
        </div>

        <ProtocolVariantSelector variants={variants} />

        <ProtocolActivitiesScheduler />

        <div className="mt-4 flex justify-end gap-4 pb-4">
          {focusedProtocolIsImplemented && focusedBaseProtocolId && (
            <div className="mr-auto">
              <RemoveUserProtocolButton
                baseProtocolId={focusedBaseProtocolId}
              />
            </div>
          )}

          <Button variant={"outline"} onClick={handleClose}>
            Cancel
          </Button>
          <SaveUserProtocolButton />
        </div>
      </div>

      <Dialog
        onOpenChange={() => {
          useSideDrawerStore.setState({
            showDiscardChangesDialog: false,
          });
        }}
        open={showDiscardChangesDialog}
      >
        <DialogContent
          data-test="confirmation-dialog"
          className="max-h-[100svh] overflow-x-hidden bg-base-100 sm:max-w-[425px]"
        >
          <DialogHeader>
            <DialogTitle className="text-xl">Unsaved changes</DialogTitle>
          </DialogHeader>
          <DialogDescription className="text-md">
            Are you sure you want to discard these changes? Your unsaved changes
            will be lost.
          </DialogDescription>
          <DialogFooter className="mt-4 flex flex-row justify-end gap-2">
            <Button
              data-test="confirmation-dialog-cancel-button"
              variant={"subtle"}
              onClick={() => {
                useSideDrawerStore.setState({
                  showDiscardChangesDialog: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              data-test="confirmation-dialog-confirm-button"
              leftIcon={faTrash}
              variant={"subtle"}
              color="destructive"
              onClick={() => {
                useSideDrawerStore.setState({
                  showDiscardChangesDialog: false,
                });
                handleClose();
              }}
            >
              Discard
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { ProtocolCustomization };
