import React from "react";
import { twMerge } from "tailwind-merge";

/**
 * Renders a ping animation. Requires a parent element with `relative` position.
 */
export const Ping = (props: { className?: string }) => {
  return (
    <>
      <span
        className={twMerge(
          "absolute -left-1 -top-1 inline-flex h-2.5 w-2.5 animate-ping rounded-full bg-blue-500 opacity-75",
          props.className,
        )}
      ></span>
      <span
        className={twMerge(
          "absolute -left-1 -top-1 inline-flex h-2.5 w-2.5 rounded-full bg-blue-600",
          props.className,
        )}
      ></span>
    </>
  );
};
