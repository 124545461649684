import type { ReactNode } from "react";

import type { ActivityEvent } from "@acme/db";
import { toast } from "@acme/ui/sonner";

import { cn } from "~/lib/utils";
import { useProtocolStore } from "~/store/calendar";
import { api } from "~/utils/api";

interface ToggleProps {
  activityEventId: string;
  className?: string;
  children?: ReactNode;
}

export const ActivityEventToggle = (props: ToggleProps) => {
  const { activityEventId } = props;
  const { toggle } = useToggleCompletedAt(activityEventId);

  return (
    <div
      data-test="activity-event-toggle"
      className={cn("h-full", props.className)}
      onClick={(e) => {
        void toggle.mutate({ activityEventId });
        e.stopPropagation();
      }}
    >
      {props.children}
    </div>
  );
};

export const useToggleCompletedAt = (activityEventId: string) => {
  const { activityEvents } = useProtocolStore();
  const activityEvent =
    activityEvents[activityEventId] ?? ({} as ActivityEvent);

  const isChecked = !!activityEvent.completedAt;

  const toggle = api.activityEvent.toggleCompletedAt.useMutation({
    onMutate: () => {
      toggleIsCheckedInStore(activityEventId, {
        completedAt: !isChecked ? null : new Date(),
      });
    },
    onError: (error) => {
      toggleIsCheckedInStore(activityEventId, {
        completedAt: isChecked ? null : new Date(),
      });
      toast.error(error.message, { position: "bottom-center" });
    },
    onSuccess: (data) => {
      toggleIsCheckedInStore(activityEventId, {
        completedAt: data.completedAt,
      });
      toast.success(
        `Activity marked as ${data.completedAt ? "complete" : "incomplete"}`,
        { position: "bottom-center" },
      );
    },
  });

  return { toggle };
};

const toggleIsCheckedInStore = (
  activityEventId: string,
  { completedAt }: { completedAt: Date | null },
) => {
  useProtocolStore.setState((state) => {
    const event = state.activityEvents[activityEventId];
    if (!event) throw new Error("Activity event not found");
    return {
      ...state,
      activityEvents: {
        ...state.activityEvents,
        [activityEventId]: {
          ...event,
          completedAt: completedAt,
        },
      },
    };
  });
};
